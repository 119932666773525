import { Container } from '@mui/material';
import colors from '../../colors/colors';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import './ProtectionsDonnees.scss';


const ProtectionsDonnees = () => {
    return (
        <div className={"cgu"} style={{ overflowX: "hidden" }}>
            <Container maxWidth={false} sx={{ py: 12, bgcolor: colors.dark, mt: 12 }}>
                <h1 className='h1-white asar align-center'>Protections des données</h1>
            </Container>
            <CustomContainer>
                <div style={{ paddingTop: 80, paddingBottom: 50 }}>
                    <p><b>Dernière modification: 13 Juillet 2023</b></p>

                    <h2>Définition</h2>
                    <p>Une « Donnée personnelle » ou donnée à caractère personnel est une information relative à une personne physique identifiée ou qui peut être identifiée directement ou indirectement à partir de cette donnée. Le terme « Traitement » désigne les opérations ou les ensembles d'opérations portant sur des Données Personnelles, quel que soit le procédé utilisé
                    </p>
                    <p>Lorsque nous évoquons un « Tiers » dans le cadre de la présente Politique, il s'agit d'une personne physique ou morale ou de tout autre organisme qui n'est ni la personne dont les Données personnelles sont traitées ni JULIA ou ses sous-traitants.</p>

                    <h2>Responsable du traitement</h2>
                    <p>Julia est le responsable du Traitement des Données Personnelles collectées directement ou indirectement auprès des visiteurs du site internet et des utilisateurs du service.</p>

                    <h2>Utilisation et Collecte des données Personnelles</h2>
                    <p>Pour utiliser les services de Julia, la communication de certaines informations est obligatoire. Le défaut de communication de ces données aura pour conséquence l'impossibilité pour Julia de traiter votre demande d'utilisation d'un service ou d'information sur ce service.</p>
                    <p>Les Données Personnelles collectées vous concernant sont utilisées par Julia, notamment, aux fins suivantes: <br />
                        <b>&bull; </b> vous permettre d'utiliser les services de tarification et de souscription en ligne ; <br />
                        <b>&bull; </b> vous permettre d'accéder à l'ensemble des fonctionnalités proposées par le site Internet ;<br />
                        <b>&bull; </b> vous permettre de demander des informations sur les offres ; <br />
                        <b>&bull; </b> cerner vos besoins pour vous fournir les offres les mieux adaptées ; <br />
                        <b>&bull; </b> d'être rappelé par un sous-traitant, et vous guider dans le processus de souscription ; <br />
                        <b>&bull; </b> gérer la vie de votre contrat.
                    </p>
                    <p>En fournissant votre adresse e-mail, et après votre consentement, vous autorisez expressément Julia à en faire usage avec d'autres Données Personnelles utiles pour vous envoyer des messages à des fins commerciales ou marketing.</p>
                    <p>Julia est également susceptible d'utiliser votre adresse e-mail à d'autres fins notamment pour vous notifier d'importants changements apportés à ses services.</p>

                    <h2>Durée de Conservation des Données Personnelles</h2>
                    <p>Toutes les Données Personnelles collectées sont conservées pour une durée limitée en fonction de la finalité du traitement et de la durée de conservation prévue par la législation en vigueur.</p>

                    <h2>Non Communication</h2>
                    <p>Vos Données Personnelles ne seront jamais vendues, partagées ou communiquées à des Tiers par Julia en dehors des cas prévus ci-après.</p>
                    <p>Vos Données Personnelles pourront être communiquées à des Tiers agissant pour notre compte dans le cadre d'un Traitement spécifique et conforme aux finalités pour lesquelles elles ont été recueillies initialement.</p>
                    <p>Ces Tiers sont tenus de les traiter conformément à la présente Politique. Ils ne sont ni autorisés à les vendre ni à les divulguer à d'autres Tiers.</p>
                    <p>Enfin, les Données Personnelles pourront être divulguées à un Tiers si nous y sommes contraints par la loi ou par une disposition réglementaire ou si cette divulgation est nécessaire dans le cadre d'une requête judiciaire ou d'une procédure contentieuse.</p>

                    <h2>Sécurité et Confidentialité</h2>
                    <p>Notre préoccupation est de préserver la qualité, la confidentialité et l'intégrité de vos Données Personnelles. Pour assurer la sécurité et la confidentialité des Données Personnelles que nous recueillons, nous utilisons des moyens techniques (réseaux protégés par des dispositifs standards tels que par pare-feu, cloisonnement réseau, hébergement physique adapté, …) et organisationnels (contrôle d'accès strict et nominatif, procédures, politique de sécurité, …).</p>
                    <p>Lors du traitement de vos Données Personnelles, nous prenons toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
                    <p>Les personnes ayant accès à vos Données Personnelles sont liées par un devoir de confidentialité et de secret, elles s'exposent à des mesures disciplinaires et/ou d'autres sanctions si elles ne respectent pas ces obligations.</p>
                    <p>Toutefois, malgré nos efforts pour protéger vos Données Personnelles, Julia ne peut pas garantir l'infaillibilité de cette protection contre toute erreur pouvant survenir lors de la transmission de Données Personnelles, au vu des risques inévitables d'une transmission par Internet et qui échappent à toute possibilité raisonnable de son contrôle.</p>

                    <h2>Transfert des Données Personnelles à l'étranger</h2>
                    <p>Dans le cas de transferts autorisés hors Union Européenne, la protection de vos Données Personnelles, conformément à la législation européenne est garantie par la signature de conventions avec les entités situées hors de l'Union Européenne ou par d'autres mécanismes reconnus par la législation en vigueur.</p>

                    <h2>Cookies</h2>
                    <p>Nous sommes susceptibles de recueillir et traiter les informations concernant votre visite du site Internet Julia via des « cookies » afin d'améliorer le contenu du site Internet et des services.</p>
                    <p><b>Qu'est-ce qu'un cookie ? </b><br />
                        Un cookie est un fichier d'information envoyé sur votre navigateur et enregistré au sein de votre terminal (ex : ordinateur, smartphone). Ce fichier peut comprendre des informations telles que votre adresse IP, votre fournisseur d'accès Internet, votre système d'exploitation, ainsi que la date et l'heure d'accès. Les cookies ne risquent en aucun cas d'endommager votre terminal.
                    </p>
                    <p>Il permet à ces sites de mémoriser vos actions et préférences (nom d'utilisateur, langue, taille des caractères et autres paramètres d'affichage) pendant un temps donné, pour que vous n'ayez pas à ré-indiquer ces informations à chaque fois que vous consultez ces sites ou naviguez d'une page à une autre.</p>
                    <p><b>Comment utilisons-nous les cookies ?</b><br />
                        Seul Julia peut lire ou modifier les informations qui sont contenues sur ses propres cookies</p>
                    <p>
                        Il existe différents types de cookies:<br />
                        <b>&bull; </b>des cookies de session qui disparaissent dès que vous quittez le site Julia ;<br />
                        <b>&bull; </b>des cookies permanents qui demeureront sur votre terminal jusqu'à expiration de leur durée de vie ou jusqu'à ce qu'ils soient supprimés à l'aide des fonctionnalités de votre navigateur.<br />
                    </p>
                    <p>Les finalités des cookies installés sur votre terminal par Julia sont :<br />
                        <b>&bull; </b>faciliter la navigation sur le site Julia ;<br />
                        <b>&bull; </b>analyser des tendances et des mesures d'audience du site Julia et établir des statistiques ;<br />
                        <b>&bull; </b>permettre la fourniture des Services Julia.<br />
                    </p>
                    <p>Comment contrôler les cookies: <br />
                        <b>&bull; </b>Vous avez la possibilité de paramétrer votre navigateur pour choisir d'accepter tous les cookies ou de les rejeter systématiquement ou encore de choisir ceux qu'il accepte selon l'émetteur ;<br />
                        <b>&bull; </b>Vous pouvez également régulièrement supprimer les cookies de votre terminal via votre navigateur.<br />
                    </p>
                    <p>Toutefois une désactivation des cookies pourrait empêcher l'utilisation ou l'accès à certaines fonctionnalités du site Julia.</p>

                    <h2>Utilisation de vos Données à des fins de prospection</h2>
                    <p>Conformément à la réglementation applicable, toute prospection par courrier électronique, SMS ou MMS, ainsi que toute cession ou location des données à des fins de prospection utilisant ces moyens, ne seront réalisées qu'avec votre consentement préalable à recevoir des prospections via ce canal ou/et à la cession de ses données.</p>
                    <p>Si vous acceptez de recevoir de tels messages en cochant les cases d'acceptation prévues à cet effet sur le site Julia, il vous sera possible à tout moment de revenir sur ce consentement. De plus, chacun des courriers électroniques de prospection vous offrira la possibilité de vous opposer pour le futur à l'envoi de nouveaux courriers de même nature par Julia.</p>
                    <p>À tout moment et sans frais, vous pouvez vous opposer à toute prospection par d'autres moyens en contactant Julia via le formulaire de contact disponible sur notre site Internet.</p>

                    <h2>Droit d'accès, de modification, de suppression, de portabilité et d'opposition</h2>
                    <p>Chaque fois que nous traitons des Données Personnelles, nous prenons toutes les mesures raisonnables pour nous assurer de l'exactitude et de la pertinence de vos Données Personnelles au regard des finalités pour lesquelles nous les collectons.</p>
                    <p>Conformément au Règlement (UE) 2016/679 Général relatif à la Protection des Données à caractère Personnel et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, vous disposez d'un droit d'accès à vos informations et Données Personnelles, de modification, de suppression, de portabilité et d'opposition à leur Traitement pour des raisons légitimes, c'est-à-dire si ce Traitement n'est pas raisonnablement nécessaire à la poursuite de notre intérêt légitime ou au respect de la loi.</p>
                    <p>Les demandes de suppression de Données Personnelles seront soumises aux obligations et exception prévues par la législation.</p>
                    <p>Vous pouvez exercer vos droits à tout moment et obtenir communication des informations et données Personnelles vous concernant en s'adressant à Julia –  143 Boulevard René Cassin - Immeuble Nouvel’R - Bat C - CS 63278 - 06205 Nice CEDEX 3.</p>
                    <p>La demande signée doit être adressée par courrier avec avis de réception et comporter une copie de votre pièce d'identité.</p>

                    <h2>Modifications de la politique de protection des Données Personnelles</h2>
                    <p>La présente Politique de protection des Données Personnelles peut être mise à jour en fonction des besoins d'Julia et des circonstances ou si la loi et la réglementation l'exigent</p>
                </div>
            </CustomContainer>
        </div>
    );
};


export default ProtectionsDonnees;