import { Box, Stack } from '@mui/material';
import loadingSvg from "../../assets/images/loading.svg";
import './Loading.scss';


export default function Loading() {
    return (
        <Stack justifyContent={"center"} alignItems={"center"}>
            <Box component="img" src={loadingSvg} width={100} />
        </Stack>
    );
};