/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
    styled, FormControl, TextField, Checkbox, MenuItem,
    Box, Button, Grid, Stack, FormControlLabel, Select, OutlinedInput, Divider, useMediaQuery, useTheme
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import { FaRegClock, FaUser, FaUsers, } from "react-icons/fa";
import { FaChildren, FaUserGroup } from "react-icons/fa6";
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import colors from '../../colors/colors';
import oneIcon from "../../assets/icons/one.svg";
import twoIcon from "../../assets/icons/two.svg";
import threeIcon from "../../assets/icons/three.svg";
import SatisfactionClients from '../../components/SatisfactionClients/SatisfactionClients';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminService } from '../../services/services';
import Loading from '../../components/Loading/Loading';
import { IoTrophyOutline, IoWarning } from 'react-icons/io5';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { IoMdStar } from "react-icons/io";
import { FiCheck, FiLock } from "react-icons/fi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import ugipLogo from "../../assets/logos/UGIP_ASSURANCES_Logo_horizontal_RVB 1.svg";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import neolianeLogo from "../../assets/logos/Logo_Néoliane_RVB.png";
import generaliLogo from "../../assets/logos/1200px-Assicurazioni_Generali_(logo).svg.png";
import micLogo from "../../assets/logos/Logo-MIC_courrier.png";
import './ParcoursSanteSimple.scss';


const typeAssurances = [
    { label: "Vous-même", icon: <FaUser color={colors.blue1} size={35} /> },
    { label: "Vous-même et votre conjoint", icon: <FaUserGroup color={colors.blue1} size={35} /> },
    { label: "Vous-même et vos enfants", icon: <FaChildren color={colors.blue1} size={35} /> },
    { label: "Vous-même, votre conjoint et vos enfants", icon: <FaUsers color={colors.blue1} size={35} /> },
];

const VOUS = typeAssurances[0].label
const VOUS_CONJOINT = typeAssurances[1].label
const VOUS_ENFANTS = typeAssurances[2].label
const VOUS_CONJOINT_ENFANTS = typeAssurances[3].label

const codePostalRegex = new RegExp('^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$');
const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colors.green,
    },
}));

interface CustomWindow extends Window {
    dataLayer: any[],
};


function ParcoursSanteSimple() {
    const theme = useTheme();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const [loadCustom, setLoadCustom] = useState<boolean>(!["", undefined].includes(location.pathname.split("/")[2]));
    const [isCustom] = useState<boolean>(!["", undefined].includes(location.pathname.split("/")[2]));
    const [showErrorCustom, setShowErrorCustom] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [stepsData, setStepsData] = useState<any>({});
    const [lstRegime] = useState<any>(["Régime Général", "Travailleur Non Salarié", "Régime Agricole", "Régime Alsace Lorraine", "Autre"]);
    const [dataPrincipal, setDataPrincipal] = useState<any>({ regime_social: lstRegime[0] });
    const [dataConjoint, setDataConjoint] = useState<any>({ regime_social: lstRegime[0] });
    const [dataEnfants, setDataEnfants] = useState<any>([]);
    const [nbrEnfant, setNbrEnfant] = useState<number>(1);
    const [subStepOfEnfants, setSubStepOfEnfants] = useState<number>(0);
    const [subStepOfEnfantsConjoint, setSubStepOfEnfantsConjoint] = useState<number>(0);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [dataHomepage, setDataHomepage] = useState<any>({});

    const matches = useMediaQuery(theme.breakpoints.down('md'));


    useEffect(() => {
        if (!["", undefined].includes(location.pathname.split("/")[2])) {
            getHomePage();
        }

        const appendGtmScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.id = 'gtm-script';
            scriptElement.innerHTML = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WMZCJLG5');
            `;

            const headElement = document.getElementById('head');
            if (headElement) {
                headElement.appendChild(scriptElement);
            }
        };
        appendGtmScript();

    }, [location.pathname]);

    useEffect(() => {
        handleProgressValue();
    }, [activeStep, subStepOfEnfants, subStepOfEnfantsConjoint]);


    const handleStepsData = (key: string, value: any) => {
        setStepsData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleDataPrincipal = (key: string, value: any) => {
        setDataPrincipal((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleDataConjoint = (key: string, value: any) => {
        setDataConjoint((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleNombreEnfant = (nombre: number) => {
        setNbrEnfant(nombre);
    };

    const handleDataEnfants = (index: number, value: any) => {
        const newData = { age: value };
        setDataEnfants((prevState: any) => {
            const updatedData = [...prevState];
            if (index < updatedData.length) {
                updatedData[index] = newData;
            } else {
                for (let i = updatedData.length; i <= index; i++) {
                    updatedData[i] = undefined;
                }
                updatedData[index] = newData;
            }
            return updatedData;
        });
    };

    const handleGoBack = () => {
        if (subStepOfEnfants === 0 && subStepOfEnfantsConjoint === 0) {
            setActiveStep(activeStep - 1)
        } else {
            if (subStepOfEnfants !== 0) {
                setSubStepOfEnfants(2);
                setActiveStep(activeStep - 1)
            }
            if (subStepOfEnfantsConjoint !== 0) {
                setSubStepOfEnfants(2);
                setActiveStep(activeStep - 1)
            }
        }
    };

    const handleProgressValue = () => {
        if (activeStep === 0) {
            setProgressValue(0);
        }
        if (activeStep === 1) {
            if (stepsData["typeAssurance"] === VOUS || stepsData["typeAssurance"] === VOUS_CONJOINT) {
                setProgressValue(33);
            }
            if (stepsData["typeAssurance"] === VOUS_ENFANTS) {
                if (subStepOfEnfants === 0) setProgressValue(33);
                if (subStepOfEnfants === 1) setProgressValue(43);
                if (subStepOfEnfants === 2) setProgressValue(53);
            }
            if (stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                if (subStepOfEnfantsConjoint === 0) setProgressValue(33);
                if (subStepOfEnfantsConjoint === 1) setProgressValue(43);
                if (subStepOfEnfantsConjoint === 2) setProgressValue(53);
            }
        }
        if (activeStep === 2) {
            setProgressValue(66);
        }
        if (activeStep === 3) {
            setProgressValue(100);
        }
    };

    const isStepDataValid = () => {
        if (activeStep === 0) {
            return ![undefined, null].includes(stepsData["typeAssurance"]);
        }
        if (activeStep === 1) {
            if (stepsData["typeAssurance"] === VOUS) {
                return dataPrincipal["age"] >= 18 && dataPrincipal["age"] <= 99;
            }
            if (stepsData["typeAssurance"] === VOUS_CONJOINT) {
                const validAgePrincipal = dataPrincipal["age"] >= 18 && dataPrincipal["age"] <= 99;
                const validAgeConjoint = dataConjoint["age"] >= 18 && dataConjoint["age"] <= 99
                return validAgePrincipal && validAgeConjoint;
            }
            if (stepsData["typeAssurance"] === VOUS_ENFANTS) {
                if (subStepOfEnfants === 0) {
                    return true;
                }
                if (subStepOfEnfants === 1) {
                    return dataPrincipal["age"] >= 18 && dataPrincipal["age"] <= 99;
                }
                if (subStepOfEnfants === 2) {
                    return (dataEnfants.length !== 0 ? dataEnfants.every((enfant: any) => enfant["age"] >= 0 && enfant["age"] <= 30) : true);
                }
            }
            if (stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                if (subStepOfEnfantsConjoint === 0) {
                    return true;
                }
                if (subStepOfEnfantsConjoint === 1) {
                    const validAgePrincipal = dataPrincipal["age"] >= 18 && dataPrincipal["age"] <= 99;
                    const validAgeConjoint = dataConjoint["age"] >= 18 && dataConjoint["age"] <= 99
                    return validAgePrincipal && validAgeConjoint;
                }
                if (subStepOfEnfantsConjoint === 2) {
                    return (dataEnfants.length !== 0 ? dataEnfants.every((enfant: any) => enfant["age"] >= 0 && enfant["age"] <= 30) : true);
                }
            }
        }
        if (activeStep === 2) {
            if (stepsData["typeAssurance"] === VOUS || stepsData["typeAssurance"] === VOUS_ENFANTS) {
                return ![undefined, null].includes(dataPrincipal["regime_social"]);
            }
            if (stepsData["typeAssurance"] === VOUS_CONJOINT || stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                return ![undefined, null].includes(dataPrincipal["regime_social"]) && ![undefined, null].includes(dataConjoint["regime_social"]);
            }
        }
        if (activeStep === 3) {
            return dataPrincipal["accept_data"] && phoneNumberRegex.test(dataPrincipal["telephone"]) && emailRegEx.test(dataPrincipal["email"]) && codePostalRegex.test(dataPrincipal["code_postal"]);
        }
    };

    const getHomePage = async () => {
        await AdminService.api_get_home_page(location.pathname).then((data: any) => {
            if (data['erreur']) {
                navigate("/");
            } else {
                setDataHomepage(data);
                setLoadCustom(false);
            }
        }).catch(() => {
            setShowErrorCustom(true);
        });
    };

    const showOrHideDialog = (status: boolean) => {
        if (status) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
            reinitializeValues();
        }
    };

    const addRdv = async () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setLoading(true);

        let dataAdherents: any = {
            gender: "",
            prenom: "",
            nom: "",
            email: dataPrincipal["email"],
            telephone: dataPrincipal["telephone"],
            code_postal: dataPrincipal["code_postal"],
            type_devis: "DevisSante",
            remarques: "",
            regime_social: dataPrincipal["regime_social"],
            date_naissance: getBirthdateFromAge(parseInt(dataPrincipal["age"])),
            has_enfant: dataEnfants?.length > 0,
            enfants: dataEnfants.length > 0 ? dataEnfants.map((enfant: any) => {
                return {
                    ...enfant,
                    date_naissance: getBirthdateFromAge(enfant.age)
                };
            }) : dataEnfants,
            has_conjoint: dataConjoint?.hasOwnProperty("age"),
            conjoint: dataConjoint?.hasOwnProperty("age") ? { ...dataConjoint, date_naissance: getBirthdateFromAge(parseInt(dataConjoint["age"])) } : {}
        };

        const utms = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_adname"];
        utms.forEach((utm, _index) => {
            if (queryParams.get(utm)) dataAdherents[utm] = queryParams.get(utm);
        });

        if (!dataAdherents.has_conjoint) {
            const newData = { ...dataAdherents };
            delete newData["conjoint"];
            dataAdherents = newData;
        }

        const origine = queryParams.get("utm_medium") ?? "Création site internet - landing sante";
        await AdminService.api_add_rdv(dataAdherents, origine, "DIAMANT").then(async () => {
            showOrHideDialog(true);
            if (typeof window !== 'undefined') {
                const customWindow = window as unknown as CustomWindow;
                customWindow.dataLayer.push({
                    'event': 'formSubmit.Success'
                });
            }
        }).catch(() => {
            setShowError(true);
        });
        setLoading(false);
    };

    const getBirthdateFromAge = (age: number) => {
        const today = new Date();
        const birthYear = today.getFullYear() - age;
        const birthdate = new Date(birthYear, today.getMonth(), today.getDate());
        const formattedBirthdate = `${birthdate.getFullYear()}-${((birthdate.getMonth() + 1) + '').padStart(2, '0')}-${(birthdate.getDate() + '').padStart(2, '0')}`;
        return formattedBirthdate;
    };

    const reinitializeValues = () => {
        setActiveStep(0);
        setStepsData({});
        setDataPrincipal({ regime_social: lstRegime[0] });
        setDataConjoint({ regime_social: lstRegime[0] });
        setDataEnfants([]);
        setNbrEnfant(1);
        setSubStepOfEnfants(0);
        setSubStepOfEnfantsConjoint(0);
        setProgressValue(0);
        setShowError(false);
    };

    const handleGoToNextStep = () => {
        if ([0, 2].includes(activeStep)) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {
            if (stepsData["typeAssurance"] === VOUS || stepsData["typeAssurance"] === VOUS_CONJOINT) {
                setActiveStep(activeStep + 1);
            }
            if (stepsData["typeAssurance"] === VOUS_ENFANTS) {
                if ([0, 1].includes(subStepOfEnfants)) {
                    setSubStepOfEnfants(subStepOfEnfants + 1)
                } else {
                    setActiveStep(activeStep + 1);
                }
            }
            if (stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                if ([0, 1].includes(subStepOfEnfantsConjoint)) {
                    setSubStepOfEnfantsConjoint(subStepOfEnfantsConjoint + 1)
                } else {
                    setActiveStep(activeStep + 1);
                }
            }
        }
        if (activeStep === 3) { addRdv() };
    };


    return (
        <div style={{ overflowX: "hidden", background: `url(${dataHomepage["background_image"]}) fixed no-repeat center / cover` }}>
            <Stack pt={{ md: !queryParams.get('partenaire') ? 20 : 10, xs: !queryParams.get('partenaire') ? 17 : 7 }} pb={{ md: !queryParams.get('partenaire') ? 7 : 10, xs: 0 }}>
                {((): any => {
                    if (loadCustom) {
                        return (
                            <Box><Loading /></Box>
                        )
                    }
                    if (!loadCustom && showErrorCustom) {
                        return (
                            <Stack alignItems={"center"}>
                                <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                    <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                        <IoWarning size={22} /> Une erreur s'est produite lors du chargement de la page
                                    </small>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (!loadCustom && !showErrorCustom) {
                        return (
                            <>
                                <CustomContainer>
                                    <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} width={"100%"} alignItems={"start"}>
                                        <Stack gap={1}>
                                            <h3 className={matches ? `text-center m-0` : `m-0`}>
                                                {
                                                    isCustom ? (<span style={{ color: "#fff" }}>{dataHomepage["titre"]}</span>) :
                                                        (<span><span className="text-pink">Réalisez des économies </span>sur votre assurance santé</span>)
                                                }
                                            </h3>
                                            <p className={matches ? `text-center m-0` : `m-0`}>
                                                {isCustom ? (<span style={{ color: "#fff" }}>{dataHomepage["paragraphe_1"]}</span>) : (
                                                    <span>
                                                        Remplissez les informations ci-dessous pour votre demande de devis.
                                                    </span>)
                                                }
                                            </p>
                                        </Stack>
                                        <Stack width={{ xs: "100%", md: "unset" }} alignItems={"end"} mt={2}>
                                            <Stack direction={"row"} gap={1} className='century' alignItems={"center"} border={`2px solid ${colors.blue1}`} p={1} borderRadius={50}>
                                                <FaRegClock color={colors.blue1} /> <p className='text-blue-1 century-bold m-0' style={{ fontSize: 15 }}>2 minutes</p>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Box mt={{ md: 7, xs: 3 }} width={"100%"}>
                                        <BorderLinearProgress variant="determinate" value={progressValue} />
                                        <Stack className='card-simple' width={"100%"}>
                                            {((): any => {
                                                if (loading) {
                                                    return (
                                                        <Stack p={6}>
                                                            <Box><Loading /></Box>
                                                        </Stack>
                                                    )
                                                }
                                                if (!loading && showError) {
                                                    return (
                                                        <Stack p={4}>
                                                            <Stack alignItems={"center"}>
                                                                <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                                                    <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                                                        <IoWarning size={22} /> Une erreur s'est produite lors de la création de votre demande.
                                                                    </small>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack alignItems={"center"} width={"100%"}>
                                                                <Button variant="contained"
                                                                    onClick={() => reinitializeValues()}
                                                                    className={"btn-suivant"}
                                                                >
                                                                    Réesayer
                                                                </Button>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                }
                                                if (!loading && !showError) {
                                                    return (
                                                        <Stack p={{ md: 6, xs: 1.5 }}>
                                                            {((): any => {
                                                                if (openDialog) {
                                                                    return (
                                                                        <Stack px={{ xs: 0, md: 20 }}>
                                                                            <SuccessMessage showOrHideDialog={showOrHideDialog} />
                                                                        </Stack>
                                                                    )
                                                                }
                                                                if (activeStep === 0 && !openDialog) {
                                                                    return (
                                                                        <>
                                                                            <h3 className='text-center m-0' style={{ fontSize: 28 }}>Qui souhaitez-vous assurer ?</h3>
                                                                            <Stack px={{ xs: 0, md: 20 }} mt={4}>
                                                                                <Grid container spacing={1.5}>
                                                                                    {
                                                                                        typeAssurances.map((as, index) => (
                                                                                            <Grid container item md={3} xs={6} justifyContent={"center"} key={index}>
                                                                                                <Stack borderRadius={2.5} key={index} width={"100%"} alignItems={"center"} justifyContent={"center"} p={3}
                                                                                                    className={as.label === stepsData["typeAssurance"] ? "card-assure-blue" : "card-assure"}
                                                                                                    onClick={() => { handleStepsData("typeAssurance", as.label); setActiveStep(activeStep + 1) }}
                                                                                                >
                                                                                                    {as.icon}
                                                                                                    <p className="text-center century-bold" style={{ fontSize: 16, color: as.label === stepsData["typeAssurance"] ? colors.blue1 : "" }}>{as.label}</p>
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                        ))
                                                                                    }
                                                                                </Grid>
                                                                            </Stack>
                                                                        </>
                                                                    )
                                                                }
                                                                if (activeStep === 1 && !openDialog) {
                                                                    return (
                                                                        <>
                                                                            {((): any => {
                                                                                if (stepsData["typeAssurance"] === VOUS) {
                                                                                    return (
                                                                                        <>
                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setActiveStep(activeStep - 1)} />
                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Votre âge ?</h3>
                                                                                            </Box>
                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }}>
                                                                                                        Votre âge est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans:)</i>
                                                                                                    </p>
                                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("age", event.target.value)} variant="outlined" value={dataPrincipal["age"] ?? ""} />
                                                                                                </FormControl>
                                                                                            </Stack>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                if (stepsData["typeAssurance"] === VOUS_CONJOINT) {
                                                                                    return (
                                                                                        <>
                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setActiveStep(activeStep - 1)} />
                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Votre âge et celui de votre conjoint ?</h3>
                                                                                            </Box>
                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }} >
                                                                                                        Votre âge est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans) :</i>
                                                                                                    </p>
                                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("age", event.target.value)} variant="outlined" value={dataPrincipal["age"] ?? ""} />
                                                                                                </FormControl>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }} >
                                                                                                        L'âge de votre conjoint est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans) :</i>
                                                                                                    </p>
                                                                                                    <TextField onChange={(event: any) => handleDataConjoint("age", event.target.value)} variant="outlined" value={dataConjoint["age"] ?? ""} />
                                                                                                </FormControl>
                                                                                            </Stack>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                if (stepsData["typeAssurance"] === VOUS_ENFANTS) {
                                                                                    return (
                                                                                        <>
                                                                                            {((): any => {
                                                                                                if (subStepOfEnfants === 0) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setActiveStep(activeStep - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Combien d'enfants avez-vous ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack flexWrap={'wrap'} direction={'row'} gap={1} mt={2} justifyContent={"center"}>
                                                                                                                {
                                                                                                                    Array.from({ length: 6 }, (_, index) => (
                                                                                                                        <Stack borderRadius={2.5} key={index} alignItems={"center"} justifyContent={"center"} px={5}
                                                                                                                            className={index + 1 === nbrEnfant ? "card-assure-blue" : "card-assure"}
                                                                                                                            onClick={() => { handleNombreEnfant(index + 1); setSubStepOfEnfants(subStepOfEnfants + 1) }}
                                                                                                                        >
                                                                                                                            <h3 style={{ color: index + 1 === nbrEnfant ? colors.blue1 : "" }}> {index + 1}</h3>
                                                                                                                        </Stack>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                if (subStepOfEnfants === 1) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setSubStepOfEnfants(subStepOfEnfants - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Votre âge ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                                <FormControl fullWidth required>
                                                                                                                    <p style={{ fontSize: 16 }} >
                                                                                                                        Votre âge est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans) :</i>
                                                                                                                    </p>
                                                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("age", event.target.value)} variant="outlined" value={dataPrincipal["age"] ?? ""} />
                                                                                                                </FormControl>
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                if (subStepOfEnfants === 2) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setSubStepOfEnfants(subStepOfEnfants - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>L'âge de vos enfants ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                                <p style={{ fontSize: 16 }}>
                                                                                                                    L'âge de vos enfants est <i style={{ opacity: 0.6 }}>(entre 0 et 30 ans) :</i>
                                                                                                                </p>
                                                                                                                {
                                                                                                                    Array.from({ length: nbrEnfant }, (_, index) => (
                                                                                                                        <FormControl fullWidth required key={index} sx={{ mt: index === 0 ? 0 : 2 }}>
                                                                                                                            <TextField variant="outlined"
                                                                                                                                onChange={(event: any) => handleDataEnfants(index, event.target.value)}
                                                                                                                                value={dataEnfants.length > 0 ? dataEnfants[index]?.age : ""}
                                                                                                                                placeholder={`Age de votre ${index + 1}${index === 0 ? "er" : "e"} enfant`}
                                                                                                                            />
                                                                                                                        </FormControl>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            })()}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                if (stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                                                                                    return (
                                                                                        <>
                                                                                            {((): any => {
                                                                                                if (subStepOfEnfantsConjoint === 0) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setActiveStep(activeStep - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Combien d'enfants avez-vous ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack flexWrap={'wrap'} direction={'row'} gap={1} mt={2} justifyContent={"center"}>
                                                                                                                {
                                                                                                                    Array.from({ length: 6 }, (_, index) => (
                                                                                                                        <Stack borderRadius={2.5} key={index} alignItems={"center"} justifyContent={"center"} px={5}
                                                                                                                            className={index + 1 === nbrEnfant ? "card-assure-blue" : "card-assure"}
                                                                                                                            onClick={() => { handleNombreEnfant(index + 1); setSubStepOfEnfantsConjoint(subStepOfEnfantsConjoint + 1) }}
                                                                                                                        >
                                                                                                                            <h3 style={{ color: index + 1 === nbrEnfant ? colors.blue1 : "" }}> {index + 1}</h3>
                                                                                                                        </Stack>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                if (subStepOfEnfantsConjoint === 1) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setSubStepOfEnfantsConjoint(subStepOfEnfantsConjoint - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Votre âge et celui de votre conjoint ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                                <FormControl fullWidth required>
                                                                                                                    <p style={{ fontSize: 16 }} >
                                                                                                                        Votre âge est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans) :</i>
                                                                                                                    </p>
                                                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("age", event.target.value)} variant="outlined" value={dataPrincipal["age"] ?? ""} />
                                                                                                                </FormControl>
                                                                                                                <FormControl fullWidth required>
                                                                                                                    <p style={{ fontSize: 16 }} >
                                                                                                                        L'âge de votre conjoint est <i style={{ opacity: 0.6 }}>(entre 18 et 99 ans) :</i>
                                                                                                                    </p>
                                                                                                                    <TextField onChange={(event: any) => handleDataConjoint("age", event.target.value)} variant="outlined" value={dataConjoint["age"] ?? ""} />
                                                                                                                </FormControl>
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                if (subStepOfEnfantsConjoint === 2) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setSubStepOfEnfantsConjoint(subStepOfEnfantsConjoint - 1)} />
                                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>L'âge de vos enfants ?</h3>
                                                                                                            </Box>
                                                                                                            <Stack px={{ xs: 0, md: 40 }}>
                                                                                                                <p style={{ fontSize: 16 }}>
                                                                                                                    L'âge de vos enfants est <i style={{ opacity: 0.6 }}>(entre 0 et 30 ans) :</i>
                                                                                                                </p>
                                                                                                                {
                                                                                                                    Array.from({ length: nbrEnfant }, (_, index) => (
                                                                                                                        <FormControl fullWidth required key={index} sx={{ mt: index === 0 ? 0 : 2 }}>
                                                                                                                            <TextField variant="outlined"
                                                                                                                                onChange={(event: any) => handleDataEnfants(index, event.target.value)}
                                                                                                                                value={dataEnfants.length > 0 ? dataEnfants[index]?.age : ""}
                                                                                                                                placeholder={`Age de votre ${index + 1}${index === 0 ? "er" : "e"} enfant`}
                                                                                                                            />
                                                                                                                        </FormControl>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            })()}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    )
                                                                }
                                                                if (activeStep === 2 && !openDialog) {
                                                                    return (
                                                                        <>
                                                                            {((): any => {
                                                                                if (stepsData["typeAssurance"] === VOUS || stepsData["typeAssurance"] === VOUS_ENFANTS) {
                                                                                    return (
                                                                                        <>
                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => handleGoBack()} />
                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Votre régime social</h3>
                                                                                            </Box>
                                                                                            <Stack px={{ xs: 0, md: 40 }} mt={2}>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }}>
                                                                                                        Vous êtes affilié·e au régime :
                                                                                                    </p>
                                                                                                    <Select
                                                                                                        onChange={(event: any) => handleDataPrincipal("regime_social", event.target.value)}
                                                                                                        input={<OutlinedInput value={dataPrincipal["regime_social"]} />}
                                                                                                    >
                                                                                                        {
                                                                                                            lstRegime?.map((rg: any, index: number) => (
                                                                                                                <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Stack>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                if (stepsData["typeAssurance"] === VOUS_CONJOINT || stepsData["typeAssurance"] === VOUS_CONJOINT_ENFANTS) {
                                                                                    return (
                                                                                        <>
                                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => handleGoBack()} />
                                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Régime social</h3>
                                                                                            </Box>
                                                                                            <Stack px={{ xs: 0, md: 40 }} mt={2}>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }}>
                                                                                                        Vous êtes affilié·e au régime :
                                                                                                    </p>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-label"
                                                                                                        onChange={(event: any) => handleDataPrincipal("regime_social", event.target.value)}
                                                                                                        input={<OutlinedInput value={dataPrincipal["regime_social"]} />}
                                                                                                    >
                                                                                                        {
                                                                                                            lstRegime?.map((rg: any, index: number) => (
                                                                                                                <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                                <FormControl fullWidth required>
                                                                                                    <p style={{ fontSize: 16 }}>
                                                                                                        Votre conjoint est affilié·e au régime :
                                                                                                    </p>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-label"
                                                                                                        onChange={(event: any) => handleDataConjoint("regime_social", event.target.value)}
                                                                                                        input={<OutlinedInput value={dataConjoint["regime_social"]} />}
                                                                                                    >
                                                                                                        {
                                                                                                            lstRegime?.map((rg: any, index: number) => (
                                                                                                                <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Stack>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    )
                                                                }
                                                                if (activeStep === 3 && !openDialog) {
                                                                    return (
                                                                        <>
                                                                            <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
                                                                                <KeyboardBackspaceIcon style={{ color: colors.pink, cursor: "pointer" }} onClick={() => setActiveStep(activeStep - 1)} />
                                                                                <h3 className='text-center m-0' style={{ fontSize: 28 }}>Et pour finir votre demande </h3>
                                                                            </Box >
                                                                            <Stack px={{ xs: 0, md: 40 }} mt={2}>
                                                                                <FormControl fullWidth required>
                                                                                    <p style={{ fontSize: 16 }} >
                                                                                        Votre code postal est le :
                                                                                    </p>
                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("code_postal", event.target.value)} variant="outlined" value={dataPrincipal["code_postal"] ?? ""} />
                                                                                </FormControl>
                                                                                <FormControl fullWidth required>
                                                                                    <p style={{ fontSize: 16 }} >
                                                                                        Votre numéro de téléphone est le :
                                                                                    </p>
                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("telephone", event.target.value)} variant="outlined" value={dataPrincipal["telephone"] ?? ""} />
                                                                                </FormControl>
                                                                                <FormControl fullWidth required>
                                                                                    <p style={{ fontSize: 16 }} >
                                                                                        Votre adresse email est :
                                                                                    </p>
                                                                                    <TextField onChange={(event: any) => handleDataPrincipal("email", event.target.value)} variant="outlined" value={dataPrincipal["email"] ?? ""} />
                                                                                </FormControl>
                                                                                <FormControlLabel sx={{ mt: 2 }} control={<Checkbox value={dataPrincipal["accept_data"]} onChange={() => handleDataPrincipal("accept_data", !dataPrincipal["accept_data"])} />}
                                                                                    label={
                                                                                        <small className='checkbox-label'>
                                                                                            J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis et accepte qu'on me recontacte dans le cadre de cette démarche
                                                                                        </small>
                                                                                    }
                                                                                />
                                                                            </Stack>
                                                                            <Stack px={{ xs: 0, md: 20 }} mt={3}>
                                                                                <Stack bgcolor={"#f7f8fc"} borderRadius={5} p={4}>
                                                                                    <h3 className='century-bold text-blue-1 m-0' style={{ fontSize: 28 }}>
                                                                                        🤔 Pourquoi demandons-nous ces informations ?
                                                                                    </h3>
                                                                                    <Stack alignItems={"center"} justifyContent={"center"} mt={2.5}>
                                                                                        <Divider sx={{ width: "40%", bgcolor: colors.blue1 }} />
                                                                                    </Stack>
                                                                                    <Stack pl={3} mt={2.5} gap={2}>
                                                                                        <Stack direction={"row"} alignItems={"center"} gap={1.6}>
                                                                                            <IoTrophyOutline size={30} />
                                                                                            <p className='century m-0' style={{ fontSize: 17 }}>
                                                                                                Pour <strong className='century-bold text-blue-1'>vous envoyer nos meilleures offres</strong> par email.
                                                                                            </p>
                                                                                        </Stack>
                                                                                        <Stack direction={"row"} alignItems={"center"} gap={1.6}>
                                                                                            <TfiHeadphoneAlt size={30} />
                                                                                            <p className='century m-0' style={{ fontSize: 17 }}>
                                                                                                Pour pouvoir vous attribuer un <strong className='century-bold text-blue-1'>conseiller dédié pour tout vos échanges.</strong>
                                                                                            </p>
                                                                                        </Stack>                                                                                        <Stack direction={"row"} alignItems={"center"} gap={1.6}>
                                                                                            <FiLock size={30} />
                                                                                            <p className='century m-0' style={{ fontSize: 17 }}>
                                                                                                Vos données ne seront <strong className='century-bold text-blue-1'>pas partagées</strong> à des tiers.
                                                                                            </p>
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </Stack>
                                                                        </>
                                                                    )
                                                                }
                                                            })()}
                                                            {
                                                                !openDialog && (
                                                                    <Stack alignItems={"center"} width={"100%"} mt={4}>
                                                                        <Button variant="contained" endIcon={activeStep !== 3 ? <EastIcon /> : <></>}
                                                                            onClick={() => handleGoToNextStep()}
                                                                            disabled={!isStepDataValid()}
                                                                            className={isStepDataValid() ? "btn-suivant" : "disabled-btn"}
                                                                        >
                                                                            {activeStep !== 3 ? (<>Suivant</>) : (<>Valider</>)}
                                                                        </Button>
                                                                        <Stack direction={"row"} alignItems={"center"} gap={1} mt={4} flexWrap={"wrap"} justifyContent={"center"}>
                                                                            <Stack direction={"row"} alignItems={"center"}>
                                                                                {Array.from({ length: 5 }, (_, index) => (
                                                                                    <IoMdStar key={index} color={colors.green} size={22} />
                                                                                ))}
                                                                                <p className='century m-0' style={{ fontSize: 15 }}>Avis Google</p>
                                                                            </Stack>
                                                                            <Stack direction={"row"} alignItems={"center"}>
                                                                                <Stack direction={"row"} alignItems={"center"}>
                                                                                    <FiCheck color={colors.pink} size={23} className='century-bold' />
                                                                                    <p className='century-bold m-0' style={{ fontSize: 15 }}>Gratuit</p>
                                                                                </Stack>
                                                                                <Stack direction={"row"} alignItems={"center"}>
                                                                                    <FiCheck color={colors.pink} size={23} className='century-bold' />
                                                                                    <p className='century-bold m-0' style={{ fontSize: 15 }}>Sans engagement</p>
                                                                                </Stack>
                                                                            </Stack>
                                                                        </Stack>
                                                                    </Stack>
                                                                )
                                                            }
                                                        </Stack>
                                                    )
                                                }
                                            })()}
                                        </Stack>
                                    </Box>
                                    {!queryParams.get('partenaire') && (
                                        <>
                                            <Stack width={"100%"} pt={{ md: 10, xs: 8 }}>
                                                {
                                                    isCustom ? (<h2 style={{ color: "#fff" }}>{dataHomepage["titre2"]}</h2>) : (
                                                        <h2>Pourquoi choisir Julia <span className='bg-doodle-pink'> Assurance</span> ?</h2>
                                                    )
                                                }
                                                <Grid container py={{ md: 6, xs: 2 }} rowSpacing={{ md: 6, xs: 2 }} columnSpacing={4} justifyContent={"center"}>
                                                    <Grid item md={4} xs={12} >
                                                        <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                                            <Box component="img" pb={4} src={oneIcon} alt="un icon" width={{ md: "70px", xs: "36px" }} />
                                                            <h3 style={{ margin: 0, paddingBottom: 6 }}>Expertise reconnue en assurance </h3>
                                                            <p>Bénéficiez de notre savoir-faire approfondi pour des solutions d'assurance sur-mesure.</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                                            <Box component="img" pb={4} src={twoIcon} alt="deux icon" width={{ md: "70px", xs: "36px" }} />
                                                            <h3 style={{ margin: 0, paddingBottom: 6 }}>Service client de qualité</h3>
                                                            <p>Nos conseillers chaleureux et à l’écoute sont à votre disposition</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                                            <Box component="img" pb={4} src={threeIcon} alt="trois icon" width={{ md: "70px", xs: "36px" }} />
                                                            <h3 style={{ margin: 0, paddingBottom: 6 }}>Gamme étendue de produits d’assurance</h3>
                                                            <p>Trouvez l'assurance idéale parmi un large choix adapté à chaque besoin spécifique.</p>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack width={"100%"} pt={{ md: 6, xs: 6 }} pb={4}>
                                                <h2 style={{ color: isCustom ? "#fff" : "unset" }}>Nos partenaires assureurs de <span className='bg-doodle-green'>confiance</span> </h2>
                                                <Stack direction={"row"} py={{ md: 8, xs: 4 }} alignItems={"center"} justifyContent={"center"} spacing={{ md: 12, xs: 4 }} flexWrap={"wrap"} >
                                                    <Box component="img" src={ugipLogo} alt="ugip" width={{ md: 120, xs: 80 }} />
                                                    <Box component="img" src={aprilLogo} alt="april" width={{ md: 120, xs: 80 }} />
                                                    <Box component="img" src={neolianeLogo} alt="neoliane" width={{ md: 120, xs: 80 }} />
                                                    <Box component="img" src={generaliLogo} alt="generali" width={{ md: 120, xs: 80 }} />
                                                    <Box component="img" src={micLogo} alt="mic" width={{ md: 120, xs: 80 }} />
                                                </Stack>
                                            </Stack>
                                        </>
                                    )}
                                </CustomContainer>
                                {!queryParams.get('partenaire') && (<SatisfactionClients />)}
                            </>
                        )
                    }
                })()}
            </Stack>
        </div>
    );
};

export default ParcoursSanteSimple;