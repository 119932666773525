import { useState } from 'react';
import { Box, IconButton, Drawer, Stack, Tooltip } from '@mui/material';
import logoJulia from "../../assets/logos/Logo_RVB.svg";
import CloseIcon from '@mui/icons-material/Close';
import ParcoursService from '../../services/services';
import { changeCurrentErrorMessage, changeLoading, changePanier, changeShowPanier } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ugipLogo from "../../assets/logos/UGIP_ASSURANCES_Logo_horizontal_RVB 1.svg";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import neolianeLogo from "../../assets/logos/Logo_Néoliane_RVB.png";
import apviaLogo from "../../assets/logos/apivia.png";
import './Panier.scss';


const provenanceImage: any = {
    julia: logoJulia,
    ugip: ugipLogo,
    neoliane: neolianeLogo,
    apivia: apviaLogo,
    april: aprilLogo,
};


const Panier = () => {
    const dispatch = useDispatch();
    const panier = useSelector((store: any) => store.panier.panier);
    const panierGlobal = useSelector((store: any) => store.panierGlobal.panierGlobal);
    const member = useSelector((store: any) => store.member.member);
    const showPanier = useSelector((store: any) => store.showPanier.showPanier);
    const [currentDevis] = useState<any>(localStorage.getItem('secu_id_sante'));
    const product = "DevisSanteClient";


    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) { return; }
        dispatch(changeShowPanier(open));
    };


    const changeStatus = async (devis: any, newStatus: string) => {
        const idDevis = devis.id;
        await ParcoursService.api_change_status_devis(idDevis, newStatus, product).then((data: any) => {
            if (data['erreur']) {
                dispatch(changeLoading(false));
                dispatch(changeCurrentErrorMessage(data["erreur"]));
            } else {
                dispatch(changeLoading(false));
                devis.status = newStatus;
                dispatch(changePanier(data['success']['devis_active']));
            }
        }).catch(() => {
            dispatch(changeLoading(false));
        });
    };


    return (
        <Drawer anchor={"right"} open={showPanier} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 450 }} role="presentation" p={3}>
                <Stack width={"100%"} alignItems={"end"}>
                    <IconButton size="large" aria-controls="menu-appbar" onClick={toggleDrawer(false)}>
                        <CloseIcon sx={{ color: "#a3a3a3" }} />
                    </IconButton>
                </Stack>
                <h4>Votre panier est de {panierGlobal?.prime} €/mois</h4>
                {
                    panier?.map((devis: any, index: number) => (
                        <Stack width={"100%"} pt={3} key={index}>
                            {
                                devis.nom_produit !== '' && (
                                    <Stack width={"100%"} spacing={1} pb={3}>
                                        <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} spacing={1}>
                                            <b>🏥 Devis {devis.type}</b>
                                            {devis.provenance !== '' && (<Box component="img" src={provenanceImage[devis.provenance]} alt="Hero" width={100} />)}
                                        </Stack>
                                        <p>
                                            {devis.nom_produit} {devis.nom_formule}
                                            <br />
                                            {
                                                devis.provenance === 'april' && (
                                                    <small >
                                                        Pack famille: {devis.has_option_renfort_famille} <br />
                                                        Pack senior: {devis.has_option_renfort_senior}
                                                    </small>
                                                )
                                            }
                                            {
                                                devis.provenance === 'apivia' && (
                                                    <small>
                                                        <Tooltip title="Pack Bien-être et Prévention 2" placement="right">
                                                            <span>Pack bep2: {devis.pack_bep2}</span>
                                                        </Tooltip>
                                                        <Tooltip title="Pack Optique - Dentaire - Audio" placement="right">
                                                            <span>Pack bep2: {devis.pack_oda}</span>
                                                        </Tooltip>
                                                        <Tooltip title="Pack Hospitalisation & Honoraire" placement="right">
                                                            <span>Pack bep2: {devis.pack_hh}</span>
                                                        </Tooltip>
                                                        <Tooltip title="Pack Bien-être et Prévention 1" placement="right">
                                                            <span>Pack bep2: {devis.pack_bep1}</span>
                                                        </Tooltip>
                                                    </small>
                                                )
                                            }
                                        </p>
                                        <p>Prise d'effet le <b className="text-bold">{devis.date_effet}</b></p>
                                        <p style={{ fontSize: 20 }}>{devis.prime_mensuel} <small>€/mois</small></p>
                                        {
                                            devis.status === 'active' && devis.id !== currentDevis && (
                                                <p onClick={() => changeStatus(devis, 'cancel')} className='m-0 align-center btn-modifier'>
                                                    Enlever du panier
                                                </p>
                                            )
                                        }
                                    </Stack>
                                )
                            }
                            {
                                devis.nom_produit === '' && (
                                    <>
                                        <b>🏥 Devis {devis.type}</b>
                                        <br /><br />
                                        <i><small>Pas de tarif disponible, veuillez continuer le parcours !</small></i>
                                    </>
                                )
                            }
                            <hr style={{ color: "#99999950", marginBottom: 25, width: "100%" }} />
                        </Stack>
                    ))
                }
                <h4>Mes informations</h4>
                <Stack width={"100%"} spacing={1.5} py={2}>
                    <p className='m-0'>
                        <span className="text-bold">Nom: </span>
                        {
                            member?.nom && member?.prenom ? (
                                <span>{member?.nom} {member?.prenom}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                    <p>
                        <span className="text-bold">Email: </span>
                        {
                            member?.email ? (
                                <span>{member?.email}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                    <p>
                        <span className="text-bold">Téléphone: </span>
                        {
                            member?.telephone ? (
                                <span>{member?.telephone}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                    <p>
                        <span className="text-bold">Date de naissance: </span>
                        {
                            member?.date_naissance ? (
                                <span>{member?.date_naissance}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                    <p>
                        <span className="text-bold">Code postal: </span>
                        {
                            member?.code_postal ? (
                                <span>{member?.code_postal}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                    <p>
                        <span className="text-bold">Régime: </span>
                        {
                            member?.regime_social ? (
                                <span>{member?.regime_social}</span>
                            ) : <i><small>Non fourni</small></i>
                        }
                    </p>
                </Stack>
            </Box>
        </Drawer>
    );
};

export default Panier;