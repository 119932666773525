/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from "react-router-dom";
import { CustomContainer } from "../../components/CustomContainer/CustomContainer";
import { useEffect, useState } from "react";
import ParcoursService from "../../services/services";
import { Stack } from "@mui/material";
import Loading from "../../components/Loading/Loading";
import iconMeb from "../../assets/images/meb/icon_meb.png";
import iconErreurMeb from "../../assets/images/meb/icon_erreur_meb.png";
import "./Bienvenue.scss";


export default function Bienvenue() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [loading, setLoading] = useState<boolean>(false);
    //const [lstId, setLstId] = useState<string>();
    const [meb, setMeb] = useState<string>();
    const [content, setContent] = useState<any>();
    const [erreurMessage, setErreurMessage] = useState<string>();

    let lstId = '';


    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        if (!queryParams.get('mid') && !queryParams.get('did') && !queryParams.get('lst_id') && !queryParams.get('signature')) {
            setLoading(false);
            setErreurMessage("Une erreur s'est produite");
        } else {
            if (queryParams.get('mid')) {
                localStorage.setItem('private_id_sante', queryParams.get('mid') as string);
            }
            if (queryParams.get('did')) {
                localStorage.setItem('secu_id_sante', queryParams.get('did') as string);
            }
            if (queryParams.get('lst_id')) {
                lstId = queryParams.get('lst_id') as string;
            }
            if (queryParams.get('signature') === 'erreur' || queryParams.get('signature') === 'abandon') {
                erreurContrat();
                setMeb('erreur');
                setLoading(false);
            } else {
                mebContrat();
            }
        }
    }, []);

    const erreurContrat = (): void => {
        ParcoursService.api_erreur_contrat().then(() => {
            setLoading(false);
        }).catch((error) => {
            setMeb('erreur');
            setErreurMessage(error);
            setLoading(false);
        });
    };

    const mebContrat = () => {
        setMeb('');
        ParcoursService.api_meb_contrat(lstId).then((data: any) => {
            if (data['erreur']) {
                erreurContrat();
                setMeb('erreur');
                setErreurMessage(data['erreur']);
            } else if (data['progress']) {
                window.open(data['progress'], '_self');
            } else {
                if (data['success']) {
                    setMeb('success');
                    setLoading(false);
                    setContent(data['success']);
                    localStorage.removeItem('secu_id_sante');
                    localStorage.removeItem('private_id_sante');
                }
            }
            setLoading(false);
        }).catch((error: any) => {
            setMeb('erreur');
            setErreurMessage(error);
            setLoading(false);
        });
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                {
                    loading && (
                        <Stack alignItems={"center"} mt={30} mb={30}>
                            <Loading />
                        </Stack>
                    )
                }
                {meb === 'success' && !loading && (
                    <Stack width={"100%"} mt={20}>
                        <div className="welcome">
                            <div>
                                <img src={iconMeb} alt="Welcome Icon" />
                            </div>
                            <br />
                            <div>
                                <h2 className="text-center" style={{ color: "white" }}>Bienvenue chez Julia Assurance {content['name_assuré']} !</h2>
                                <h4 className="text-center century-bold" style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: String(content['text_principal']) }}></h4>
                                {content['text_prev'] !== '' && (
                                    <div className="prev century-bold" style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: String(content['text_prev']) }}></div>
                                )}
                                <br />
                                <p className="text-center century-bold" style={{ color: "white" }}>Vous avez reçu sur votre email {content['mail_assuré']} un mail vous confirmant votre souscription.</p>
                            </div>
                        </div>
                        <br />
                        <div className="contact-bienvenue">
                            <p>
                                Une question ? Un doute ?<br /> Vous pouvez nous contacter sur votre adresse client dédié →
                                client@julia-assurance.fr <br />
                                (réponse en moins de 2h sur nos horaires de travail) <br />ou nous téléphoner au 01 86 76 72 91
                            </p>
                        </div>
                        <br />
                    </Stack>
                )}

                {(meb === 'erreur' || erreurMessage) && !loading && (
                    <Stack width={"100%"} mt={20}>
                        <div className="welcome">
                            <div>
                                <img src={iconErreurMeb} alt="Error Icon" />
                            </div>
                            <br />
                            <div>
                                <h3>Oups ! Une erreur s'est produite lors de la signature de votre contrat !</h3>
                            </div>
                        </div>
                        <div className="contact-bienvenue">
                            <p>Vous pouvez nous téléphoner au 01 86 76 72 91</p>
                        </div>
                        <br />
                    </Stack>
                )}
            </CustomContainer >
        </div >
    );
};