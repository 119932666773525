import { Box, Grid, Stack } from '@mui/material';
import logoJulia from "../../assets/logos/Logo_Blanc_RVB.svg";
import colors from '../../colors/colors';
import { CustomContainer } from '../CustomContainer/CustomContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import './Footer.scss';


const Footer = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();


    return (
        <>
            {(!location.pathname.includes("document-upload") && !location.pathname.includes("document") && !location.pathname.includes("signature") && !queryParams.get('partenaire') && !queryParams.get('branding')) && (
                <Stack bgcolor={colors.dark1} py={5}>
                    <CustomContainer>
                        <Grid container alignItems={"start"}>
                            <Grid item md={6} xs={12}>
                                <Stack direction={"column"}>
                                    <Box component="img" pb={5} src={logoJulia} alt="logo Julia" width={{ md: 219, xs: 125 }} />
                                    <h3 className='h3-white'>À propos de Julia</h3>
                                    <p className='p-white'>
                                        {
                                            location.pathname !== "/devis-sante-simple" && (<>
                                                Julia est une Société par Actions Simplifiée (SAS), au capital de 5 000€ et est inscrite au registre du commerce et des sociétés de Paris sous le numéro 881 435 358.
                                                <br /><br />
                                            </>)
                                        }
                                        Julia est une filiale de la société Groupe Santiane, S.A.S au capital de 2.350.835 €, immatriculée au registre du commerce et des sociétés de Paris sous le n°524 248 093 et dont le siège social est situé 30/32 Boulevard de Vaugirard, 75015 Paris.
                                        <br /><br />
                                        Opérant sous la marque Julia Assurance, la société est régie par le Code des Assurances et est immatriculée au Registre ORIAS, sous le numéro 20002363.
                                    </p>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack direction={"column"} textAlign={{ md: "right", xs: "left" }} mt={{ md: 0, xs: 3 }}>
                                    <h3 className='h3-white'>Reclamation</h3>
                                    <p className='p-white' style={{ paddingTop: 25 }}>
                                        Vous pouvez nous écrire via l'adresse <br /> contact@julia-assurance.fr
                                        <br /> <br />
                                        ou nous contacter au 01 86 76 72 91
                                    </p>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Stack py={3}>
                            <hr style={{ color: "#808187", width: "100%" }} />
                        </Stack>
                        <Stack direction={{ md: "row", xs: "column-reverse" }} justifyContent={"space-between"}>
                            <p className='p-white'>@ {new Date().getFullYear()} Julia Assurance. Tous droits réservés</p>
                            <Stack direction={"row"} spacing={4} alignItems={"center"}>
                                <p className='text-white cursor-pointer' onClick={() => navigate("/mentions-legales")}>Mentions légales</p>
                                <p className='text-white cursor-pointer'>FAQ</p>
                                <p className='text-white cursor-pointer' onClick={() => window.open("https://www.facebook.com/julia.mutuelle", '_blank')}>Facebook</p>
                            </Stack>
                        </Stack>
                    </CustomContainer >
                </Stack >
            )}
        </>
    );
};


export default Footer;