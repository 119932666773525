import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import clockIcon from "../../assets/icons/clock.svg";
import './CardOffre.scss';


export default function CardOffre(props: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const data = props.data;

    return (
        <Stack direction={"row"} spacing={2} alignItems={"center"} bgcolor={"white"} p={2} borderRadius={3}>
            <Stack justifyContent={"start"} alignItems={"start"}>
                <Box component="img" src={data.image} alt="rh image" width={{ md: 120, xs: 80 }} />
                {matches && (<p className='text-white bg-badge' style={{ backgroundColor: data.color }}>{data.contrat}</p>)}
            </Stack>
            <Stack direction={"column"} spacing={1} alignItems={"baseline"}>
                <p className='m-0' style={{ fontSize: matches ? 20 : 23 }}>{data.title}</p>
                <Stack direction={"row"} alignItems={"center"}>
                    <Box component="img" mr={1} src={data.pinIcon} alt="pin icon" />
                    <p className='m-0'>{data.adresse}</p>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Box component="img" mr={1} src={clockIcon} alt="clock icon" />
                    <p className='m-0'>Mise en ligne le {data.date}</p>
                </Stack>
                {!matches && (<p className='text-white bg-badge' style={{ backgroundColor: data.color }}>{data.contrat}</p>)}
            </Stack>
        </Stack>
    );
};