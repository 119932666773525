import {
    CHANGE_STEP,
    CHANGE_STEP_SHOWED,
    CHANGE_CURRENT_ERROR_MESSAGE,
    CHANGE_INIT_DATA,
    CHANGE_INFO,
    CHANGE_STEPS_DATA,
    CHANGE_BUTTON_STATE,
    CHANGE_LOADING_STATE,
    CHANGE_PANIER,
    CHANGE_SHOW_PANIER,
    CHANGE_MEMBER,
    CHANGE_PANIER_GLOBAL,
    CHANGE_STEP_EMPRUNTEUR,
    CHANGE_CURRENT_SUBSTEP,
    CHANGE_STEPS_DATA_EMPRUNTEUR,
    CHANGE_INIT_DATA_EMPRUNTEUR,
    CHANGE_CAN_BE_SIGNED,
    CHANGE_PARCOURS_TYPE
} from './constants';


const initialState = {
    step: 0,
    stepShowed: [{ index: 0, title: "Vos besoins" }],
    currentErreurMessage: null,
    initData: null,
    info: false,
    stepsData: {
        "criteres-sante": {},
        "beneficiaires-sante": {},
        "coordonees-sante": {},
        "formule-sante": {},
        "informations-sante": {},
        "informations-paiement": {},
        "signature": {}
    },
    isButtonEnabled: false,
    loading: false,
    panier: null,
    panierGlobal: null,
    member: null,
    showPanier: false,
    canBeSigned: false,
    parcoursType: "",
    stepEmprunteur: 0,
    currentSubstep: 0,
    stepsDataEmprunteur: {
        "informations-bien": {},
        "informations-pret": {},
        "informations-personnelles": {},
        "offre-emprunteur": {},
        "informations-complementaires": {},
        "signature": {}
    },
    initDataEmprunteur: null
};


export const parcoursReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CHANGE_STEP:
            return {
                ...state,
                step: action.payload
            };
        case CHANGE_STEP_SHOWED:
            return {
                ...state,
                stepShowed: action.payload
            };
        case CHANGE_CURRENT_ERROR_MESSAGE:
            return {
                ...state,
                currentErreurMessage: action.payload
            };
        case CHANGE_INIT_DATA:
            return {
                ...state,
                initData: action.payload
            };
        case CHANGE_INFO:
            return {
                ...state,
                info: action.payload
            };
        case CHANGE_STEPS_DATA:
            return {
                ...state,
                stepsData: action.payload
            };
        case CHANGE_BUTTON_STATE:
            return {
                ...state,
                isButtonEnabled: action.payload
            };
        case CHANGE_LOADING_STATE:
            return {
                ...state,
                loading: action.payload
            };
        case CHANGE_PANIER:
            return {
                ...state,
                panier: action.payload
            };
        case CHANGE_PANIER_GLOBAL:
            return {
                ...state,
                panierGlobal: action.payload
            };
        case CHANGE_MEMBER:
            return {
                ...state,
                member: action.payload
            };
        case CHANGE_SHOW_PANIER:
            return {
                ...state,
                showPanier: action.payload
            };
        case CHANGE_CAN_BE_SIGNED:
            return {
                ...state,
                canBeSigned: action.payload
            };
        case CHANGE_PARCOURS_TYPE:
            return {
                ...state,
                parcoursType: action.payload
            };
        // FOR PARCOURS EMPRUNTEUR CLIENT
        case CHANGE_STEP_EMPRUNTEUR:
            return {
                ...state,
                stepEmprunteur: action.payload
            };
        case CHANGE_CURRENT_SUBSTEP:
            return {
                ...state,
                currentSubstep: action.payload
            };
        case CHANGE_STEPS_DATA_EMPRUNTEUR:
            return {
                ...state,
                stepsDataEmprunteur: action.payload
            };
        case CHANGE_INIT_DATA_EMPRUNTEUR:
            return {
                ...state,
                initDataEmprunteur: action.payload
            };
        default:
            return state;
    }
};