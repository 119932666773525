import { Container } from '@mui/system';
import { Box, Button, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import femmeImage from "../../assets/images/AC2A4333.jpg";
import hommeImage from "../../assets/images/AC2A4288.jpg";
import homme2Image from "../../assets/images/AC2A4134.jpg";
import illustrationImage from "../../assets/images/illustration.svg";
import illustration2Image from "../../assets/images/illustration2.svg";
// import femmeRhImage from "../../assets/images/femme_rh.png";
import femme2RhImage from "../../assets/images/femme2_rh.png";
import hommeRhImage from "../../assets/images/homme_rh.png";
// import homme2RhImage from "../../assets/images/homme2_rh.png";
import oneIcon from "../../assets/icons/one.svg";
import twoIcon from "../../assets/icons/two.svg";
import threeIcon from "../../assets/icons/three.svg";
// import pinPinkIcon from "../../assets/icons/pin_pink.svg";
import pinBlueIcon from "../../assets/icons/pin_blue.svg";
import pinGreenIcon from "../../assets/icons/pin_green.svg";
import colors from '../../colors/colors';
import CardOffre from '../../components/CardOffre/CardOffre';
// import VideoSection from '../../components/VideoSection/VideoSection';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import './Join.scss';


const offres = [
    // { image: femmeRhImage, title: "Commercial startup H/F", adresse: "Paris 13e", date: "30/05/2023", contrat: "CDI", color: colors.pink, pinIcon: pinPinkIcon },
    { image: hommeRhImage, title: "Commercial assurance emprunteur", adresse: "Reims", date: "02/01/2024", contrat: "CDD", color: colors.teal, pinIcon: pinBlueIcon },
    { image: femme2RhImage, title: "Commercial assurance santé", adresse: "Paris 13e", date: "02/01/2024", contrat: "CDI", color: colors.green, pinIcon: pinGreenIcon },
    // { image: homme2RhImage, title: "Commercial startup H/F", adresse: "Reims", date: "30/05/2023", contrat: "Alternance", color: colors.pink, pinIcon: pinPinkIcon }
];


function Join() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const handleSendCandidature = () => {
        const recipient = 'rh@julia-assurance.fr';
        const subject = '';
        const body = '';

        const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoUrl, '_blank');
    };

    const goToIndeed = () => {
        window.open("https://fr.indeed.com/cmp/Julia-31d2485a", '_blank');
    };

    return (
        <div style={{ overflowX: "hidden" }}>
            <Container className={"half-background px-0"} maxWidth={false} style={{ padding: 0 }}>
                <CustomContainer>
                    <Stack direction={"column"} width={"100%"} pt={{ xs: 15, md: 20 }} pb={{ md: 15, xs: 5 }} alignItems={"center"} spacing={4}>
                        <h1 className='h1-white'>Rejoins la #Team ! 🔥</h1>
                        <p className='p-white' style={{ fontSize: matches ? 16 : 18, textAlign: "center" }}>
                            Chez Julia Assurance, nous recherchons constamment à <span className='p-bold'> compléter notre #dreamteam dynamique et ambitieuse </span>!
                            Nous sommes impatients de te rencontrer et de construire l'avenir de Julia Assurance ensemble !
                        </p>
                    </Stack>
                    <Grid container alignItems={"start"} spacing={{ md: 8, xs: 2 }}>
                        <Grid container item md={4} xs={4} spacing={matches ? 2 : 7}>
                            <Grid item md={12} xs={12} >
                                <Box component="img" src={hommeImage} alt="homme" width={"100%"} borderRadius={{ md: "24px", xs: "10px" }} />
                            </Grid>
                            <Grid item md={12} xs={12} >
                                <Box component="img" src={illustrationImage} alt="famille" width={"100%"} />
                            </Grid>
                        </Grid>
                        <Grid container item md={4} xs={4} justifyContent={"center"}>
                            <Grid item md={12} xs={12}>
                                <Box component="img" src={femmeImage} alt="femme" width={"100%"} borderRadius={{ md: "24px", xs: "10px" }} />
                            </Grid>
                        </Grid>
                        <Grid container item md={4} xs={4} spacing={matches ? 2 : 7}>
                            <Grid item md={12} xs={12} >
                                <Box component="img" src={illustration2Image} alt="famille" width={"100%"} />
                            </Grid>
                            <Grid item md={12} xs={12} >
                                <Box component="img" src={homme2Image} alt="homme" width={"100%"} borderRadius={{ md: "24px", xs: "10px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </Container>
            <CustomContainer>
                <Stack width={"100%"} pt={10}>
                    <h2>Top 3 des raisons de rejoindre notre <span className='bg-doodle-pink'> #dreamteam</span> :</h2>
                    <Grid container py={{ md: 6, xs: 2 }} rowSpacing={{ md: 6, xs: 3 }} columnSpacing={4} justifyContent={"center"}>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={oneIcon} alt="un icon" width={{ md: "54px", xs: "30px" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Une ambiance de folie </h3>
                            <p>
                                Chez nous, c'est comme une grande famille ! Les blagues fusent, <span className='p-bold'> les sourires sont contagieux et les moments de partage ne manquent pas. </span>
                                On organise aussi des événements funs et des afterworks endiablés pour renforcer notre esprit d'équipe. Tu ne t'ennuieras jamais avec nous !
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={twoIcon} alt="deux icon" width={{ md: "54px", xs: "30px" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Des perspectives de croissance infinies</h3>
                            <p>
                                Nous sommes en plein essor, et c'est le moment parfait pour rejoindre l'aventure ! En tant que membre de notre équipe, <span className='p-bold'> tu seras au coeur de notre expansion. </span>
                                Prépare-toi à repousser tes limites et à évoluer dans un environnement dynamique et en constante évolution !
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={threeIcon} alt="trois icon" width={{ md: "54px", xs: "30px" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Ton développement professionnel</h3>
                            <p>
                                Nous croyons en ton potentiel. Tu seras encouragé à <span className='p-bold'> développer tes compétences </span>, à partager tes idées et à prendre des initiatives.
                                Que tu sois un expert chevronné ou que tu démarres tout juste, chez nous, tu auras <span className='p-bold'> l'opportunité de te surpasser et d'exprimer tout en talent. </span>
                            </p>
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <Container className={"bg-join px-0"} maxWidth={false} sx={{ py: 15, mt: 3 }} >
                <CustomContainer>
                    <h2 className='h2-white'>Nos <span className={"bg-doodle-circle"}>dernières</span> offres d'emploi</h2>
                    <Grid container pt={8} spacing={{ md: 6, xs: 4 }}>
                        {
                            offres.map((offre, index) => (
                                <Grid item md={6} xs={12} key={index} onClick={goToIndeed} className='cursor-pointer'>
                                    <CardOffre data={offre} />
                                </Grid>
                            ))
                        }
                    </Grid>
                    {/* <Grid container mt={6} justifyContent={"center"}>
                        <Button className="btn-alert-pink" variant="contained">
                            Mettre une alerte
                        </Button>
                    </Grid> */}
                </CustomContainer>
            </Container>
            <Container className={`px-0 ${!matches ? "half-background-list" : "bg-blue-list-join"}`} maxWidth={false} sx={{ mb: { md: 20, xs: 10 } }} >
                <CustomContainer>
                    <Grid container bgcolor={colors.blue}>
                        <Grid container item md={5} xs={12} bgcolor={colors.blue} pr={{ md: 35, xs: 0 }} py={{ md: 7, xs: 0 }} alignItems={"center"}>
                            <Stack direction={"column"} spacing={2} alignItems={"baseline"}>
                                <h3 className='h3-white'>Comment se déroule le recrutement chez Julia ?</h3>
                                <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>
                                    Nous traitons rapidement les candidatures. <br />
                                    Le recrutement c'est comme les leads avec les commerciaux : il faut sauter sur
                                    <span className='bg-highlight-2'> l'occasion</span> ! 😉
                                </p>
                            </Stack>
                        </Grid>
                        <Grid item md={7} xs={12} bgcolor={{ md: "white", xs: colors.blue }} sx={{ borderTopLeftRadius: 20 }}>
                            <Stack bgcolor={"transparent"} height={"100%"} justifyContent={{ md: "space-between", xs: "unset" }} ml={{ md: -20, xs: 0 }} mt={{ md: 6, xs: 4 }} spacing={{ md: 4, xs: 4 }}>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>1</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Un premier échange rapide au téléphone pour se faire une idée de ton profil.</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>2</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Un entretien physique avec l’une de nos deux RH.</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>3</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Un échange avec ton futur formateur </p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>4</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Une prise de décision rapide pour que tu nous rejoignes au plus vite </p>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </Container>
            {/* <VideoSection /> */}
            <Container className={"px-0"} maxWidth={false} sx={{ py: 10, mt: 40, backgroundColor: colors.blue }} >
                <CustomContainer>
                    <Grid container alignItems={"center"}>
                        <Grid item md={8}>
                            <Stack spacing={4}>
                                <h2 className='h2-white'>Tu n'as pas trouvé l'offre qui te correspond et tu penses être notre prochaine pépite ?</h2>
                                <p className='p-white' style={{ fontSize: "18px" }}>
                                    Nous attendons ta candidature spontanée ! 😉
                                </p>
                            </Stack>
                        </Grid>
                        <Grid container item md={4} justifyContent={{ md: "end", xs: "center" }} mt={{ md: 0, xs: 6 }}>
                            <Button className="btn-alert-pink" variant="contained" onClick={handleSendCandidature} fullWidth={matches ?? false}>
                                Envoyer ma candidature
                            </Button>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </Container>
        </div>
    );
};


export default Join;