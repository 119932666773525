import { Grid, Stack, Box, Avatar, useTheme, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import leftIcon from "../../assets/icons/arrow left circle.svg";
import rightIcon from "../../assets/icons/arrow right circle.svg";
import StarRateIcon from '@mui/icons-material/StarRate';
import { CustomContainer } from '../CustomContainer/CustomContainer';
import './Reussite.scss';


const avis = [
    {
        initial: "J",
        color: "#63a0f1",
        nom: "Julie Tardan",
        date: "il y a 2 mois",
        remarque: "Gaetan m'a bien aidée à trouver l'offre de mutuelle faite pour mon couple, en plus d'avoir su se rendre disponible, il a été soucieux de bien me renseigner et sa conversation empreinte d'humour et de légèreté met sincèrement à l'aise. Les cadre des offres mutuelles est souvent flou pour la plupart des gens, il j'ai eu un conseiller précis, tranquillisant et surtout qui met en confiance !!!! Chapeau bas!",
        visited: "mars"
    },
    {
        initial: "C",
        color: "#014d41",
        nom: "Camille Gueron",
        date: "il y a 3 semaines",
        remarque: "J'ai contacté par Inès une conseillère vraiment agréable qui a su me conseiller sur les différentes mutuelles et sur les options que je voulais. Je me suis engagé avec une mutuelle grâce à ses conseils. Merci pour tout.",
        visited: "avril"
    },
];


export default function Reussite() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const childContainerStyles = {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        py: 4,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const childStyles = {
        flex: '0 0 auto',
        maxWidth: '320px',
        minWidth: '320px',
    };


    return (
        <Container className={`px-0 ${!matches ? "half-background-emprunteur" : "bg-blue"}`} maxWidth={false} sx={{ py: 10, pb: { md: 10, xs: 2 }, mb: { md: 9, xs: 0 } }}>
            <CustomContainer>
                <h2 className='h2-white'>Nos <span className={"bg-highlight-1"}>réussites</span></h2>
                <Grid container justifyContent={"end"} display={{ md: "grid", xs: "none" }}>
                    <Stack direction={"row"} alignItems={"center"} mt={6} spacing={2}>
                        <Box component="img" src={leftIcon} alt="left icon" className='cursor-pointer' />
                        <Box component="img" src={rightIcon} alt="right icon" className='cursor-pointer' />
                    </Stack>
                </Grid>
                {
                    !matches ? (
                        <Grid container columnSpacing={6} px={17} mt={5}>
                            {
                                avis.map((av, index) => (
                                    <Grid container item md={6} xs={12} key={index}>
                                        <Box p={2} bgcolor={"white"} borderRadius={3} boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19)"}>
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Avatar sx={{ bgcolor: av.color }}><span style={{ color: "white" }}>{av.initial}</span></Avatar>
                                                <Stack direction={"column"}>
                                                    <p className='m-0 century-bold'>{av.nom}</p>
                                                    <small className='small'>1 avis</small>
                                                </Stack>
                                            </Stack>
                                            <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1}>
                                                <Stack direction={"row"}>
                                                    {
                                                        Array.from({ length: 5 }, (_, index) => (
                                                            <small key={index}>
                                                                <StarRateIcon sx={{ color: "#faba00", fontSize: 20 }} />
                                                            </small>
                                                        ))
                                                    }
                                                </Stack>
                                                <small className='small'>{av.date}</small>
                                            </Stack>
                                            <p className='century'>{av.remarque}</p>
                                            <small className='small'>Visité en {av.visited}</small>
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    ) : (
                        <Stack direction={"row"} spacing={3.5} mt={5} sx={childContainerStyles} className="hidden-scroll">
                            {
                                avis.map((av, index) => (
                                    <Box key={index} p={2} bgcolor={"white"} borderRadius={3} boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19)"} style={{ ...childStyles }}>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            <Avatar sx={{ bgcolor: av.color }}><span style={{ color: "white" }}>{av.initial}</span></Avatar>
                                            <Stack direction={"column"}>
                                                <p className='m-0 century-bold'>{av.nom}</p>
                                                <small className='small'>1 avis</small>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1}>
                                            <Stack direction={"row"}>
                                                {
                                                    Array.from({ length: 5 }, (_, index) => (
                                                        <small key={index}>
                                                            <StarRateIcon sx={{ color: "#faba00", fontSize: 20 }} />
                                                        </small>
                                                    ))
                                                }
                                            </Stack>
                                            <small className='small'>{av.date}</small>
                                        </Stack>
                                        <p className='century'>{av.remarque}</p>
                                        <small className='small'>Visité en {av.visited}</small>
                                    </Box>
                                ))
                            }
                        </Stack>
                    )
                }
            </CustomContainer>
        </Container>
    );
};