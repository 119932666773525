/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Slide, Divider, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import { DatePicker } from '@mui/x-date-pickers';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsDataEmprunteur } from '../../../redux/actions';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../ParcoursEmprunteur.scss';


const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');


export default function InfoPersonnelles() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const stepEmprunteur = useSelector((store: any) => store.stepEmprunteur.stepEmprunteur);
    const currentSubstep = useSelector((store: any) => store.currentSubstep.currentSubstep);
    const initDataEmprunteur = useSelector((store: any) => store.initDataEmprunteur.initDataEmprunteur);
    const stepsDataEmprunteur = useSelector((store: any) => store.stepsDataEmprunteur.stepsDataEmprunteur);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);

    const [data, setData] = useState<any>(stepsDataEmprunteur["informations-personnelles"]);
    const [dataAdherents, setDataAdherents] = useState<any>(stepsDataEmprunteur["informations-personnelles"]["lst_adherents"]);
    const [activeAdherent, setActiveAdherent] = useState<number>(0);
    const activeAdherentRef = useRef(activeAdherent);

    const [lstPaysNaissance] = useState<any>(initDataEmprunteur["lst_pays"]);
    const [lstProfession] = useState<any>(initDataEmprunteur["lst_profession"]);
    const [lstStatutPro] = useState<any>(initDataEmprunteur["lst_statut_pro"]);

    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const lstQuotite = Array.from({ length: 100 - 5 + 1 }, (_, index) => 5 + index)


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        const inputElement = document.querySelector('.google-places-autocomplete__input');
        if (inputElement) {
            inputElement.addEventListener('input', (event: any) => {
                handleAdresseAdherent(activeAdherentRef.current, { adresse: event.target.value });
            });
        }
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
            if (inputElement) {
                inputElement.removeEventListener('input', handleAdresseAdherent as unknown as EventListener);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsDataEmprunteur({ ...stepsDataEmprunteur, "informations-personnelles": data }));
        if (isStepDataValid()) {
            if (dataAdherents.length === 1) {
                if (parseInt(dataAdherents[0]["quotite_base"]) !== 100) {
                    handleDataAdherents(0, "quotite_base", 100);
                }
            }
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, lst_adherents: dataAdherents }));
    }, [dataAdherents]);


    useEffect(() => {
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [currentSubstep]);

    useEffect(() => {
        activeAdherentRef.current = activeAdherent;
    }, [activeAdherent]);


    const handleDataAdherents = (index: number, key: string, value: any) => {
        setDataAdherents((prevState: any) => prevState.map((obj: any, i: number) => {
            if (i === index) {
                return {
                    ...obj,
                    [key]: value
                };
            }
            return obj;
        }));
    };

    const handleAdresseAdherent = (index: number, addressObject: { [key: string]: any }) => {
        const updatedDataAdherents = [...dataAdherents];
        if (index >= 0 && index < updatedDataAdherents.length) {
            for (const key in addressObject) {
                if (addressObject.hasOwnProperty(key)) {
                    updatedDataAdherents[index].adresse_actuelle = {
                        ...updatedDataAdherents[index].adresse_actuelle,
                        [key]: addressObject[key],
                    };
                }
            }
            handleDataAdherents(index, "adresse_actuelle", updatedDataAdherents[index].adresse_actuelle);
        }
    };

    const handleSelectAdresse = (suggestion: any) => {
        const adresseObject = {
            adresse: suggestion.description,
            ville: suggestion.terms.length > 1 ? suggestion.terms[suggestion.terms.length - 2].value : suggestion.terms[0].value
        }
        handleAdresseAdherent(activeAdherentRef.current, adresseObject);
    };

    const isStepDataValid = () => {
        if (currentSubstep === 0) {
            return dataAdherents.every((adherent: any) => {
                return (
                    adherent["prenom"] !== "" && adherent["nom"] !== "" &&
                    phoneNumberRegex.test(adherent["telephone"]) && adherent["gender"] !== "" &&
                    adherent["date_naissance"] !== "" && emailRegEx.test(adherent["email"]) &&
                    adherent["pays_naissance"] !== "" && adherent["profession"] !== "" &&
                    adherent["adresse_actuelle"]["adresse"] !== "" && adherent["adresse_actuelle"]["adresse"] !== null &&
                    adherent["adresse_actuelle"]["ville"] !== "" && adherent["adresse_actuelle"]["ville"] !== null &&
                    (adherent["profil_tabagique"] !== "" && adherent["profil_tabagique"] !== null) &&
                    lstQuotite.includes(adherent["quotite_base"]
                    ))
            });
        }
    };


    return (
        <Stack spacing={2} mt={6}>
            {currentErreurMessage && (<Error error={currentErreurMessage} />)}
            {((): any => {
                if (currentSubstep === 0) {
                    return (
                        <Slide in={stepEmprunteur === 2} {...({ timeout: 1000 })} direction='left'>
                            <Stack spacing={2}>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Informations personnelles
                                </h3>
                                <p className='century' style={{ marginTop: 1, fontSize: 15 }}>
                                    Ces informations sont nécessaires pour éditer un devis qui puisse être présenté à votre banque
                                </p>
                                {
                                    dataAdherents?.length !== 1 && (
                                        <Stack width={"100%"} spacing={3}>
                                            <Stack direction="row" spacing={2} alignItems={"center"} justifyContent={"center"}>
                                                {
                                                    dataAdherents?.map((_ad: any, index: number) => (
                                                        <Stack px={4} py={1} className={`${activeAdherent === index ? "radio-emp-blue" : "radio-emp"}`} borderRadius={10} justifyContent={"center"}
                                                            onClick={() => setActiveAdherent(index)}
                                                        >
                                                            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                                                <p className='century m-0' style={{ fontSize: 15 }}>
                                                                    {index === 0 ? "Vous" : `${matches ? "" : "Adhérent"} ${index + 1}`}
                                                                </p>
                                                            </Stack>
                                                        </Stack>
                                                    ))
                                                }
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                }
                                <Stack width={"100%"}>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Civilité"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <Stack width={"100%"}>
                                                <RadioGroup
                                                    row
                                                    sx={{ columnGap: 2, width: "100%", flexWrap: "nowrap" }}
                                                    className='radio-group'
                                                    onChange={(event: any) => handleDataAdherents(activeAdherent, "gender", event.target.value)}
                                                >
                                                    <FormControlLabel sx={{ width: "100%" }} className={dataAdherents[activeAdherent]["gender"] === "Homme" ? "radio-emp-blue" : "radio-emp"} style={{ borderRadius: 50 }} value="Homme" checked={(dataAdherents[activeAdherent]["gender"] === "Homme")} control={<Radio />} label="Monsieur" labelPlacement="end" />
                                                    <FormControlLabel sx={{ width: "100%" }} className={dataAdherents[activeAdherent]["gender"] === "Femme" ? "radio-emp-blue" : "radio-emp"} style={{ borderRadius: 50 }} value="Femme" checked={(dataAdherents[activeAdherent]["gender"] === "Femme")} control={<Radio />} label="Madame" labelPlacement="end" />
                                                </RadioGroup>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Nom"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl required fullWidth>
                                                <TextField className='input-emp' placeholder='Votre nom' onChange={(event: any) => handleDataAdherents(activeAdherent, "nom", event.target.value)} variant="outlined" value={dataAdherents[activeAdherent]["nom"]} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Prénom"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl required fullWidth>
                                                <TextField className='input-emp' placeholder='Votre prénom' onChange={(event: any) => handleDataAdherents(activeAdherent, "prenom", event.target.value)} variant="outlined" value={dataAdherents[activeAdherent]["prenom"]} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Date de naissance"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <DatePicker value={dayjs(dataAdherents[activeAdherent]["date_naissance"]) ?? ""} className='input-emp'
                                                    onChange={(value: any) => handleDataAdherents(activeAdherent, "date_naissance", moment(value.toString()).format("YYYY-MM-DD"))}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Pays de naissance"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <Select
                                                    className='input-emp'
                                                    onChange={(event: any) => handleDataAdherents(activeAdherent, "pays_naissance", event.target.value)}
                                                    input={<OutlinedInput value={dataAdherents[activeAdherent]["pays_naissance"]} />}
                                                >
                                                    {
                                                        lstPaysNaissance?.map((pn: any, index: number) => (
                                                            <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {
                                        (dataAdherents?.length > 1) && (
                                            <Grid container alignItems={"center"} mt={2}>
                                                <Grid container item xs={12} md={6}>
                                                    <Label label={"Quotité à assurer"} fontFamily={"century-bold"} />
                                                </Grid>
                                                <Grid container item xs={12} md={6}>
                                                    <FormControl fullWidth required>
                                                        <OutlinedInput className='input-emp' type='number' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                            onChange={(event: any) => handleDataAdherents(activeAdherent, "quotite_base", event.target.value)} value={dataAdherents[activeAdherent]["quotite_base"]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Fumeur"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <Stack width={"100%"}>
                                                <RadioGroup
                                                    row
                                                    sx={{ columnGap: 2, width: "100%", flexWrap: "nowrap" }}
                                                    className='radio-group'
                                                    onChange={(event: any) => handleDataAdherents(activeAdherent, "profil_tabagique", event.target.value)}
                                                >
                                                    <FormControlLabel sx={{ width: "100%" }} className={dataAdherents[activeAdherent]["profil_tabagique"] === "Fumeur" ? "radio-emp-blue" : "radio-emp"} style={{ borderRadius: 50 }} value="Fumeur" checked={(dataAdherents[activeAdherent]["profil_tabagique"] === "Fumeur")} control={<Radio />} label="Oui" labelPlacement="end" />
                                                    <FormControlLabel sx={{ width: "100%" }} className={dataAdherents[activeAdherent]["profil_tabagique"] === "Non fumeur" ? "radio-emp-blue" : "radio-emp"} style={{ borderRadius: 50 }} value="Non fumeur" checked={(dataAdherents[activeAdherent]["profil_tabagique"] === "Non fumeur")} control={<Radio />} label="Non" labelPlacement="end" />
                                                </RadioGroup>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Profession"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <Select
                                                    className='input-emp'
                                                    onChange={(event: any) => handleDataAdherents(activeAdherent, "profession", event.target.value)}
                                                    input={<OutlinedInput value={dataAdherents[activeAdherent]["profession"]} />}
                                                >
                                                    {
                                                        lstProfession?.map((pn: any, index: number) => (
                                                            <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Statut professionnel"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <Select
                                                    className='input-emp'
                                                    onChange={(event: any) => handleDataAdherents(activeAdherent, "statut_professionnel", event.target.value)}
                                                    input={<OutlinedInput value={dataAdherents[activeAdherent]["statut_professionnel"]} />}
                                                >
                                                    {
                                                        lstStatutPro?.map((pn: any, index: number) => (
                                                            <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Email"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <TextField className='input-emp' placeholder='Votre adresse email' onChange={(event: any) => handleDataAdherents(activeAdherent, "email", event.target.value)} variant="outlined" value={dataAdherents[activeAdherent]["email"]} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Nº de téléphone"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl fullWidth required>
                                                <TextField className='input-emp' placeholder='Votre portable' onChange={(event: any) => handleDataAdherents(activeAdherent, "telephone", event.target.value)} variant="outlined" value={dataAdherents[activeAdherent]["telephone"]} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"} mt={2}>
                                        <Grid container item xs={12} md={6}>
                                            <Label label={"Adresse actuelle"} fontFamily={"century-bold"} />
                                        </Grid>
                                        <Grid container item xs={12} md={6}>
                                            <FormControl className='adresse-emp' fullWidth required>
                                                <GooglePlacesAutocomplete
                                                    apiKey="AIzaSyBA1dplU9rdYSCSnwdrIFg_zuoaqcxW4Rc"
                                                    placeholder="Votre adresse"
                                                    loader={<p className='century-bold' style={{ fontSize: 14 }}>Chargement des adresses...</p>}
                                                    initialValue={dataAdherents[activeAdherent]["adresse_actuelle"]["adresse"] ?? ""}
                                                    onSelect={handleSelectAdresse}
                                                    renderSuggestions={(_active, suggestions, onSelectSuggestion) => (
                                                        <div className="suggestions-container">
                                                            {suggestions.map((suggestion, index) => (
                                                                <MenuItem key={index} onClick={(event) => onSelectSuggestion(suggestion, event)}>
                                                                    {suggestion.description}
                                                                </MenuItem>
                                                            ))}
                                                        </div>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Slide>
                    )
                }
            })()}
        </Stack>
    )
};