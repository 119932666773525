import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import checkIcon from "../../assets/icons/check circle.svg"
import xIcon from "../../assets/icons/x circle.svg"
import { Box, useMediaQuery, useTheme } from '@mui/material';
import './TableEmprunteur.scss';


export default function TableEmprunteur() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <TableContainer component={"div"}>
            <Table sx={{ minWidth: matches ? "unset" : 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}></TableCell>
                        <TableCell align='center' width={"20%"}>
                            <p className='p-dark'>Achat résidentiel</p>
                        </TableCell>
                        <TableCell align='center' width={"20%"}>
                            <p className='p-dark'>Achat locatif</p>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left" colSpan={2}>
                            <span className='p-bold'> Décès </span>
                            <p className='m-0'> Fortuit (ex : accident, maladie) </p>
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={checkIcon} alt="check icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={checkIcon} alt="check icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={2}>
                            <span className='p-bold'> PTIA </span>
                            <p className='m-0'> Perte Totale et Irréversible d'Autonomie : invalidité à 100% + assistance d'une personne tierce au quotidien </p>
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={checkIcon} alt="check icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={checkIcon} alt="check icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={matches ? 2 : 0}>
                            <div>
                                <span className='p-bold'> IPT </span>
                                <p className='m-0'> Invalidité Permanente Totale (&gt; 66%) </p>
                            </div>
                            <div style={{ display: matches ? "block" : "none" }}>
                                <span className='p-bold'> ITT </span>
                                <p className='m-0'> Incapacité Totale de Travail </p>
                            </div>
                        </TableCell>
                        <TableCell align="left" sx={{ display: { md: "block", xs: "none" } }}>
                            <span className='p-bold'> ITT </span>
                            <p className='m-0'> Incapacité Totale de Travail </p>
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={checkIcon} alt="check icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={xIcon} alt="x icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={2}>
                            <span className='p-bold'> IPP </span>
                            <p className='m-0'> Invalidité Permanente Partielle (entre 33 et 66%) </p>
                        </TableCell>
                        <TableCell align="center">
                            <p style={{ fontStyle: "italic" }}>en option</p>
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={xIcon} alt="x icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={matches ? 2 : 0}>
                            <div>
                                <span className='p-bold'> Dos et Psy </span>
                                <p className='m-0'> (MNO) </p>
                            </div>
                            <div style={{ display: matches ? "block" : "none" }}>
                                <span className='p-bold'> Perte d'emploi </span>
                            </div>
                        </TableCell>
                        <TableCell align="left" sx={{ display: { md: "block", xs: "none" } }}>
                            <span className='p-bold'> Perte d'emploi </span>
                            <p className='m-0'> Incapacité Totale de Travail </p>
                        </TableCell>
                        <TableCell align="center">
                            <p style={{ fontStyle: "italic" }}>en option</p>
                        </TableCell>
                        <TableCell align="center">
                            <Box component="img" src={xIcon} alt="x icon" width={{ md: 40, xs: 20 }} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};