/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Stack, Box, useTheme, useMediaQuery } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import hospitalisationIcon from "../../../assets/icons/hospitalisation.svg";
import optiqueIcon from "../../../assets/icons/optique.svg";
import dentaireIcon from "../../../assets/icons/dentaire.svg";
import specialisteIcon from "../../../assets/icons/spécialistes.svg";
import praticienIcon from "../../../assets/icons/medecine douce.svg";
import { useDispatch, useSelector } from 'react-redux';
import { changeIsButtonEnabled, changeStepsData } from '../../../redux/actions';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import "../ParcoursSante.scss";


const besoins = [
    { title: "Hospitalisation", icon: hospitalisationIcon, name: "hospi" },
    { title: "Dentaire", icon: dentaireIcon, name: "dentaire" },
    { title: "Médecine douce", icon: praticienIcon, name: "medecine_douce" },
    { title: "Optique", icon: optiqueIcon, name: "optique" },
    { title: "Soins courants", icon: specialisteIcon, name: "soins_courant" },
];

const markLabels = { 1: "Faible", 2: "Moyen", 3: "Fort", 4: "Très fort" };
const markNoLabels = { 1: "", 2: "", 3: "", 4: "" };


export default function CriteresSante() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const [data, setData] = useState<object>(stepsData["criteres-sante"]);


    useEffect(() => {
        dispatch(changeIsButtonEnabled(true));
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "criteres-sante": data }));
    }, [data]);


    const setCritereValue = (critere: string, value: number) => {
        setData((prevState: any) => ({
            ...prevState,
            [critere]: value,
        }));
    };

    return (
        <Stack width={"100%"} spacing={2}>
            <h3 className='century-bold m-0' style={{ textAlign: !matches ? "left" : "center" }}>Vos besoin de santé</h3>
            <TableContainer component={"div"}>
                <Table sx={{ minWidth: !matches ? 650 : "unset" }}>
                    {
                        !matches && (
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center' colSpan={4} width={"65%"}></TableCell>
                                </TableRow>
                            </TableHead>
                        )
                    }
                    <TableBody>
                        {
                            besoins.map((bes, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        <Stack direction={"row"} alignItems={"center"} ml={{ xs: 2, md: 0 }}>
                                            <Box component="img" src={bes.icon} mr={1} width={30} />
                                            {!matches && (<p className='m-0'>{bes.title}</p>)}
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center" colSpan={4} width={!matches ? "unset" : "100%"}>
                                        <Stack px={!matches ? 4 : 1.5}>
                                            <Slider
                                                dots min={1} max={4} step={1} marks={(index === 0 && !matches) ? markLabels : markNoLabels}
                                                onChange={(value: any) => setCritereValue(bes.name, value)}
                                                defaultValue={stepsData["criteres-sante"][bes.name]}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack >
    );
};