/* eslint-disable react-hooks/exhaustive-deps */
import "dayjs/locale/fr";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { changeCanBeSigned, changeCurrentErrorMessage, changeInfo, changeInitData, changeLoading, changeStep, changeStepShowed, changeStepsData } from "../../redux/actions";
import { Grid, Stack, Box, Breadcrumbs, useMediaQuery, ThemeProvider, createTheme, Button, Container } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import coeurGif from "../../assets/gif/coeur.gif";
import horlogeGif from "../../assets/gif/horloge.gif";
import supportGif from "../../assets/gif/support.gif";
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CriteresSante from '../../components/ParcoursSante/CriteresSante/CriteresSante';
import BeneficiairesSante from "../../components/ParcoursSante/BeneficiairesSante/BeneficiairesSante";
import CoordonneesSante from "../../components/ParcoursSante/CoordonneesSante/CoordonneesSante";
import InfosComplementaires from "../../components/ParcoursSante/InfoComplementaires/InfosComplementaires";
import FormuleSante from "../../components/ParcoursSante/FormuleSante/FormuleSante";
import SouscriptionSante from "../../components/ParcoursSante/SouscriptionSante/SouscriptionSante";
import SignatureSante from "../../components/ParcoursSante/Signature/Signature";
import ParcoursService from "../../services/services";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import colors from '../../colors/colors';
import './ParcoursSante.scss';


const theme = createTheme({
    palette: {
        primary: {
            main: colors.blue
        }
    }
});

const gifs = [
    { title: "Garanties immédiates", icon: coeurGif },
    { title: "Remboursement 48h", icon: horlogeGif },
    { title: "Assistance 24h/24", icon: supportGif }
];

const parcoursSteps = [
    { index: 0, name: "criteres-sante", title: "Vos besoins", component: <CriteresSante /> },
    { index: 1, name: "beneficiaires-sante", title: "Vos bénéficiaires", component: <BeneficiairesSante /> },
    { index: 2, name: "coordonees-sante", title: "Vos coordonnées", component: <CoordonneesSante /> },
    { index: 3, name: "formule-sante", title: "Votre formule", component: <FormuleSante /> },
    { index: 4, name: "informations-sante", title: "Informations complémentaires", component: <InfosComplementaires /> },
    { index: 5, name: "informations-paiement", title: "Souscription", component: <SouscriptionSante /> },
    { index: 6, name: "signature", title: "Signature", component: <SignatureSante /> },
];

const stepList = {
    "criteres-sante": 0,
    "beneficiaires-sante": 1,
    "coordonees-sante": 2,
    "formule-sante": 3,
    "informations-sante": 4,
    "informations-paiement": 5,
    "signature": 6
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function ParcoursSante() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const step = useSelector((store: any) => store.step.step);
    const stepShowed = useSelector((store: any) => store.stepShowed.stepShowed);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const isButtonEnabled = useSelector((store: any) => store.isButtonEnabled.isButtonEnabled);
    const loading = useSelector((store: any) => store.loading.loading);
    const initData = useSelector((store: any) => store.initData.initData);
    const canBeSigned = useSelector((store: any) => store.canBeSigned.canBeSigned);
    const parcoursType = useSelector((store: any) => store.parcoursType.parcoursType);

    const [param, setParam] = useState<string | null>(null);
    const [log, setLog] = useState<string>("");
    const [open, setOpen] = useState(false);

    const screenXs = useMediaQuery('(min-width:900px)');
    let signature;
    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;
    const product = "DevisSanteClient";


    useEffect(() => {
        const stepParams = location.state?.stepParams;
        const finalizeParams = location.state?.finalizeParams;

        if (!localStorage.getItem('url_ad_sante')) {
            localStorage.setItem('url_ad_sante', window.location.href);
        }

        if (queryParams.get('come_back')) {
            setParam("come_back");
        }

        if (stepParams) {
            apiGoToStep(getStepIndex(stepParams));
            const newLocationState = { ...location.state, stepParams: null };
            window.history.replaceState(newLocationState, '');
        } else if (finalizeParams) {
            apiGetLastFinalize();
            const newLocationState = { ...location.state, finalizeParams: false };
            window.history.replaceState(newLocationState, '');
        } else {
            initStep(parcoursSteps[step].name);
        }

        dispatch(changeCurrentErrorMessage(null));
        return () => {
            dispatch(changeCanBeSigned(false));
        }
    }, []);


    const initStep = async (currentStep: any) => {
        dispatch(changeCurrentErrorMessage(null));
        window.scroll(0, 0);
        dispatch(changeLoading(true));
        await ParcoursService.api_init_step(currentStep, product).then((data) => {
            if (data['erreur']) {
                dispatch(changeCurrentErrorMessage(data["erreur"]));
                dispatch(changeLoading(false));
            } else if (data['info']) {
                dispatch(changeInfo(true));
                dispatch(changeLoading(false));
            } else {
                setLog(data['log']);
                dispatch(changeStepsData({ ...stepsData, [currentStep]: data['response']['form'] }));
                dispatch(changeInitData(data['response']['needed']));
                if ('url_signature' in data['response']['needed']) {
                    signature = data['response']['needed']['url_signature'];
                    if (data['response']['needed']['is_redirect'] === true) {
                        window.open(signature, '_self');
                    }
                }
            }
            dispatch(changeLoading(false));
        }).catch(() => {
            console.log("__error__init__step__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const apiGetLastFinalize = async () => {
        await ParcoursService.api_go_to_last_finalize(product, parcoursType).then(async (data) => {
            await initStep(data["step"]);
            dispatch(changeStep(stepList[data["step"] as keyof object]));
            setListStepShowed(stepList[data["step"] as keyof object]);
        }).catch(() => {
            console.log("__error_finalize__")
            dispatch(changeCurrentErrorMessage(erreur));
        })
    };

    const apiGoToStep = async (index: number) => {
        await ParcoursService.api_go_to_step(parcoursSteps[index as keyof object].name, product).then(async (data: any) => {
            await initStep(data["step"]);
            dispatch(changeStep(stepList[data["step"] as keyof object]));
            setListStepShowed(index);
        }).catch(() => {
            console.log("__error__go_to__step__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const getNextStep = async () => {
        window.scroll(0, 0)
        dispatch(changeLoading(true));
        await ParcoursService.api_get_next_step(parcoursSteps[step].name, product, stepsData[parcoursSteps[step].name], log).then(async (data: any) => {
            if (data['erreur']) {
                window.scroll(0, 0);
                dispatch(changeCurrentErrorMessage(data["erreur"]));
                dispatch(changeLoading(false));
            } else {
                if ('next_step' in data) {
                    if (data['next_step'] !== '') {
                        await initStep(data["next_step"]);
                        dispatch(changeStep(stepList[data["next_step"] as keyof object]));
                        setListStepShowed(stepList[data["next_step"] as keyof object]);
                        dispatch(changeLoading(false));
                    }
                }
            }
        }).catch(() => {
            console.log("__error__get__next__step__");
            dispatch(changeCurrentErrorMessage(erreur));
            dispatch(changeLoading(false));
        });
    };

    const goToLastStep = async () => {
        await ParcoursService.api_go_to_last_step(product).then(async (data: any) => {
            setParam("");
            window.history.replaceState(null, '', window.location.pathname);
            // Si le client peut signer on le redirige directement vers les étapes de finalisations
            if (data["email_send"]) {
                dispatch(changeCanBeSigned(true));
                await apiGetLastFinalize();
            } else {
                await initStep(data["step"]);
                dispatch(changeStep(stepList[data["step"] as keyof object]));
                setListStepShowed(stepList[data["step"] as keyof object]);
            }
        }).catch(() => {
            console.log("__error__last__step__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const reset = async () => {
        await ParcoursService.api_reset(product).then(async (data: any) => {
            setParam("");
            window.history.replaceState(null, '', window.location.pathname);
            dispatch(changeCanBeSigned(false));
            await initStep(data["step"]);
            dispatch(changeStep(stepList[data["step"] as keyof object]));
            setListStepShowed(stepList[data["step"] as keyof object]);
        }).catch(() => {
            console.log("__error__reset__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const saveDataWeb = async () => {
        dispatch(changeLoading(true));
        await ParcoursService.save_data_web(parcoursSteps[step].name, product, stepsData[parcoursSteps[step].name]).then((data: any) => {
            if (data['erreur']) {
                dispatch(changeLoading(false));
                dispatch(changeCurrentErrorMessage(data["erreur"]));
            } else {
                dispatch(changeLoading(false));
                handleOpen();
            }
        }).catch(() => {
            console.log("__error__souscription__");
            dispatch(changeCurrentErrorMessage(erreur));
            dispatch(changeLoading(false));
        });
    };

    const setListStepShowed = (index: number) => {
        const showed = parcoursSteps.filter((s: any) => s.index <= index);
        dispatch(changeStepShowed(showed));
    };

    const getStepIndex = (stepName: string) => parcoursSteps.findIndex(step => step.name === stepName);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/");
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                <Stack pt={18} pb={13}>
                    <Grid container columnSpacing={4} alignItems={"start"}>
                        <Grid container item md={3} display={{ xs: "none", md: "flex" }}></Grid>
                        <Grid container item md={9} xs={12} justifyContent={"start"}>
                            <Stack px={2} py={0.8} className='card-parcours'>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    {
                                        stepShowed.map((s: any, index: number) => (
                                            <p key={index} style={{ fontSize: 13 }}
                                                className={step === s.index ? "century-bold m-0 breadcumb-active" : "century m-0 cursor-pointer"}
                                                onClick={() => { apiGoToStep(s.index); }}
                                            >
                                                {screenXs ? s.title : index + 1}
                                            </p>
                                        ))
                                    }
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={4} alignItems={loading ? "center" : "start"} mt={3}>
                        <Grid container item md={3} display={{ xs: "none", md: "flex" }} justifyContent={"center"}>
                            <Stack width={"100%"} spacing={3} pb={3.5} className='card-parcours'>
                                {
                                    gifs.map((g, index) => (
                                        <Stack key={index} alignItems={"center"}>
                                            <Box component="img" src={g.icon} width={150} />
                                            <p className='century-bold m-0 align-center'>{g.title}</p>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        </Grid>
                        <Grid container item md={9} xs={12} justifyContent={"center"}>
                            {
                                loading ? (
                                    <Loading />
                                ) : (
                                    <Stack width={"100%"} spacing={2.5} px={{ md: 12, xs: 2 }} py={3} className='card-parcours'>
                                        <ThemeProvider theme={theme}>
                                            <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
                                                {parcoursSteps[step].component}
                                                <Grid container justifyContent={"center"} alignItems={"center"} columnGap={1.5}>
                                                    {
                                                        step !== 0 && (
                                                            <Button className="btn-precedent-bleu" onClick={() => { apiGoToStep(step - 1) }} variant="text">
                                                                Précédent
                                                            </Button>
                                                        )
                                                    }
                                                    <Button className={(isButtonEnabled && !loading) ? "btn-suivant" : "disabled-btn"} variant="contained"
                                                        onClick={() => { (step === 5 && !canBeSigned) ? saveDataWeb() : getNextStep() }}
                                                        disabled={!isButtonEnabled || loading}
                                                    >
                                                        Etape suivante
                                                    </Button>
                                                </Grid>
                                            </LocalizationProvider>
                                        </ThemeProvider>
                                    </Stack>
                                )
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            {
                param === "come_back" && (
                    <Container maxWidth={false} className="bandeau" sx={{ py: 7 }}>
                        <Stack textAlign={"center"} alignItems={"center"} spacing={5}>
                            <p className="m-0">Hello 👋 <br />Vous avez déjà effectué un devis sur notre site il y a quelques temps !</p>
                            <Stack direction={{ md: "row", xs: "column" }} flexWrap={"wrap"} gap={{ md: 5, xs: 2 }} alignItems={"center"} mt={2}>
                                <Button className="btn-suivant" onClick={() => { goToLastStep() }} variant="contained">
                                    Reprendre mon devis
                                </Button>
                                <small style={{ color: "#777" }}>ou</small>
                                <Button className="btn-suivant" onClick={() => { reset() }} variant="contained">
                                    Recommencer un devis
                                </Button>
                            </Stack>
                        </Stack>
                    </Container>
                )
            }
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <DialogTitle>
                    <p className="m-0 text-bold" style={{ fontSize: 25 }}>Merci {initData?.prenom} !</p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"}>
                        <p>Nous avons bien enregistré votre dossier {initData?.produit} {initData?.formule}.
                            <br />Par volonté de vous offrir la meilleure expérience client possible, un conseiller Julia va vous appeler pour valider votre contrat.
                            <br />Merci de votre confiance 😀
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="btn-precedent" onClick={handleClose} variant="text">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ParcoursSante;