/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from "moment";
import { Grid, Stack, RadioGroup, FormControlLabel, Radio, FormControl, Select, MenuItem, OutlinedInput } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import colors from '../../../colors/colors';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsData } from '../../../redux/actions';
import "../ParcoursSante.scss";


const getValueForm = (data: any) => {
    const values = Object.entries(data).reduce((acc: any, [key, value]: any) => {
        if (value && value.hasOwnProperty("value")) {
            acc[key as keyof object] = value.value;
        }
        return acc;
    }, {});
    return values;
};


export default function BeneficiairesSante() {
    const dispatch = useDispatch();
    const initData = useSelector((store: any) => store.initData.initData);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);

    const [dataEnfant, setDataEnfant] = useState<any>(initData["enfants"]);
    const [nbrEnfant, setNbrEnfant] = useState<number>(initData["enfants"].length);

    const [data, setData] = useState<any>(stepsData["beneficiaires-sante"]);
    const [dataConjoint, setDataConjoint] = useState<any>(
        stepsData["beneficiaires-sante"]["has_conjoint"] ? getValueForm(stepsData["beneficiaires-sante"]["conjoint"])
            :
            { prenom: "", nom: "", regime_social: "", date_naissance: "" }
    );
    const [lstRegime] = useState<any>(initData["lst_regime"]);


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "beneficiaires-sante": data }));
        if (isStepDataValid()) {
            if (!stepsData["beneficiaires-sante"]["has_conjoint"]) {
                removeConjoint();
            }
            if (!stepsData["beneficiaires-sante"]["has_enfant"]) {
                emptyEnfants();
            }
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));

        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, conjoint: dataConjoint }));
    }, [dataConjoint]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, enfants: dataEnfant }));
    }, [dataEnfant]);


    const setStepData = (key: string, value: any) => {
        if (key === "date_naissance") {
            if (!["Invalid Date", "Invalid date"].includes(value)) {
                const parsedDate = moment(value.toString());
                if (parsedDate.isValid() && parsedDate.year() >= 1900) {
                    setData((prevState: any) => ({
                        ...prevState,
                        [key]: value,
                    }));
                }
            }
        } else {
            setData((prevState: any) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    const handleDataConjoint = (key: string, value: any) => {
        setDataConjoint((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const addConjoint = () => {
        setData((prevState: any) => ({
            ...prevState,
            conjoint: dataConjoint,
        }));
    };

    const removeConjoint = () => {
        if (data.hasOwnProperty("conjoint")) {
            const newData = { ...data };
            delete newData["conjoint"];
            setData(newData);
        }
    };

    const emptyEnfants = () => {
        if (data["enfants"].length !== 0) {
            setData((prevState: any) => ({
                ...prevState,
                enfants: [],
            }));
        }
    };

    const handleNombreEnfant = (nombre: number) => {
        setStepData("has_enfant", nombre !== 0 ? true : false)
        setNbrEnfant(nombre);

        const lenData = dataEnfant.length;
        const newObjects: any = [];

        if (nombre < lenData) {
            for (let i = lenData; i >= nombre; i--) {
                setDataEnfant((prevState: any) =>
                    prevState.filter((_: any, index: number) => index !== i)
                );
            }
            return;
        } else {
            for (let i = 1; i <= nombre - lenData; i++) {
                const newObject = { prenom: "", date_naissance: "" };
                newObjects.push(newObject);
            }
            setDataEnfant((prevState: any) => [
                ...prevState,
                ...newObjects,
            ]);
        }
    };

    const handleDataEnfant = (index: number, value: any) => {
        const data = { prenom: "", date_naissance: value };
        setDataEnfant((prevState: any) =>
            prevState.map((obj: any, i: number) => (i === index ? data : obj))
        );
    };

    const isStepDataValid = () => {
        const parsedDate = moment(data["date_naissance"].toString());
        return (
            data["gender"] &&
            parsedDate.isValid() && moment().diff(parsedDate, 'years') >= 16 &&
            data["regime_social"] &&
            (data["has_conjoint"] ? (dataConjoint["date_naissance"] !== "" && dataConjoint["regime_social"]) : true) &&
            (data["has_enfant"] ? dataEnfant.every((enfant: any) => enfant["date_naissance"] !== "") : true)
        );
    };


    return (
        <Stack width={"100%"} spacing={2}>
            <section>
                {currentErreurMessage && (<Error error={currentErreurMessage} />)}
                <h3 className='century-bold m-0'>Vous êtes ?</h3>
                <Stack flexWrap={'wrap'} direction={'row'} columnGap={"35px"} rowGap={"18px"} pb={5} pt={3} alignItems={"end"}>
                    <RadioGroup
                        sx={{ columnGap: 3, rowGap: 1.5 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event: any) => setStepData("gender", event.target.value)}
                    >
                        <FormControlLabel className={"radio-item"} value="Homme" checked={(data["gender"] === "Homme")} control={<Radio />} label="Homme" labelPlacement="start" />
                        <FormControlLabel className={"radio-item"} value="Femme" checked={(data["gender"] === "Femme")} control={<Radio />} label="Femme" labelPlacement="start" />
                    </RadioGroup>
                    <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                        <FormControl fullWidth required>
                            <Label label={"Date de naissance"} />
                            <DatePicker value={dayjs(data["date_naissance"])} disableFuture
                                onChange={
                                    (value: any) => (dayjs(value).year() >= 1900 && dayjs(value).year() < new Date().getFullYear()) ? setStepData("date_naissance", moment(value.toString()).format("YYYY-MM-DD")) : {}
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth required>
                            <Label label={"Régime Social"} />
                            <Select
                                labelId="demo-simple-select-label"
                                onChange={(event: any) => setStepData("regime_social", event.target.value)}
                                input={<OutlinedInput value={data["regime_social"]} />}
                            >
                                {
                                    lstRegime?.map((rg: any, index: number) => (
                                        <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            </section>
            <section>
                <h3 className='century-bold m-0'>Souhaitez-vous couvrir votre conjoint(e) ?</h3>
                <Grid container alignItems={"start"} mt={3}>
                    <Stack direction={"row"} bgcolor={colors.blue2} py={1.5} px={1.5} borderRadius={2} >
                        <ErrorOutlineIcon sx={{ color: "white", mr: 1 }} />
                        <p className='m-0 century text-white'>-6% sur la cotisation</p>
                    </Stack>
                </Grid>
                <Stack direction={'column'} pt={2} pb={4}>
                    <RadioGroup
                        sx={{ columnGap: 3 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event: any) => setStepData("has_conjoint", JSON.parse(event.target.value))}
                    >
                        <FormControlLabel className={"radio-item"} value={true} onClick={() => addConjoint()} checked={JSON.parse(data["has_conjoint"])} control={<Radio />} label="Oui" labelPlacement="start" />
                        <FormControlLabel className={"radio-item"} value={false} onClick={() => removeConjoint()} checked={!JSON.parse(data["has_conjoint"])} control={<Radio />} label="Non" labelPlacement="start" />
                    </RadioGroup>
                </Stack>
                {
                    data["has_conjoint"] && (
                        <Stack direction={'row'} gap={"10px"} pb={5} width={"100%"}>
                            <FormControl required fullWidth>
                                <Label label={"Date de naissance"} />
                                <DatePicker value={dayjs(dataConjoint["date_naissance"])}
                                    onChange={(value: any) => handleDataConjoint("date_naissance", moment(value.toString()).format("YYYY-MM-DD"))}
                                />
                            </FormControl>
                            <FormControl required fullWidth>
                                <Label label={"Régime Social"} />
                                <Select
                                    labelId="demo-simple-select-label"
                                    onChange={(event: any) => handleDataConjoint("regime_social", event.target.value)}
                                    input={<OutlinedInput value={dataConjoint["regime_social"] ?? ""} />}
                                >
                                    {
                                        lstRegime?.map((rg: any, index: number) => (
                                            <MenuItem value={rg} key={index}>{rg}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    )
                }
            </section>
            <section>
                <h3 className='century-bold m-0'>Combien d'enfants avez-vous à protéger ?</h3>
                <Stack flexWrap={'wrap'} direction={'row'} gap={"4px"} pb={5} mt={3}>
                    {
                        Array.from({ length: 7 }, (_, index) => (
                            <small key={index} className={index === nbrEnfant ? 'm-0 number-select number-active' : 'm-0 number-select'}
                                onClick={() => handleNombreEnfant(index)}
                            >
                                {index}
                            </small>
                        ))
                    }
                </Stack>
                {
                    data["has_enfant"] && (
                        <Stack flexWrap={'wrap'} direction={'row'} gap={"10px"} pb={3} width={"100%"}>
                            {
                                dataEnfant.map((_: any, index: number) => (
                                    <FormControl required key={index}>
                                        <Label label={`Date de naissance de l'enfant ${index + 1}`} />
                                        <DatePicker value={dayjs(dataEnfant[index]["date_naissance"])}
                                            onChange={(value: any) => handleDataEnfant(index, moment(value.toString()).format("YYYY-MM-DD"))}
                                        />
                                    </FormControl>
                                ))
                            }
                        </Stack>
                    )
                }
            </section>
        </Stack>
    );
};