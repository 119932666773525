/* eslint-disable react-hooks/exhaustive-deps */
import { merge } from 'rxjs';
import { Button, Fade, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidBank } from 'react-icons/bi';
import colors from '../../../colors/colors';
import Error from '../../Error/Error';
import Loading from "../../Loading/Loading";
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsDataEmprunteur } from '../../../redux/actions';
import ParcoursService from '../../../services/services';
import '../ParcoursEmprunteur.scss';


const setProduitsAndProduitsInitial = (data: any) => {
    let produits: any[] = [];
    let produitsInitial: any[] = [];

    if (data?.produits) {
        Object.keys(data.produits).forEach((produit) => {
            produits.push({
                id: data.produits[produit],
                nom: produit,
                loading: true
            });

            produitsInitial.push({
                id: data.produits[produit],
                nom: produit,
                loading: true
            });
        });
        return { produits: produits, produitsInitial: produitsInitial }
    }
};


export default function OffreEmprunteur(props: any) {
    const dispatch = useDispatch();
    const stepEmprunteur = useSelector((store: any) => store.stepEmprunteur.stepEmprunteur);
    const currentSubstep = useSelector((store: any) => store.currentSubstep.currentSubstep);
    const initDataEmprunteur = useSelector((store: any) => store.initDataEmprunteur.initDataEmprunteur);
    const stepsDataEmprunteur = useSelector((store: any) => store.stepsDataEmprunteur.stepsDataEmprunteur);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);
    const loading = useSelector((store: any) => store.loading.loading);

    const [data, setData] = useState<any>(stepsDataEmprunteur["offre-emprunteur"]);
    const [dataTarif, setDataTarif] = useState<any>(stepsDataEmprunteur["offre-emprunteur"]["tarif"]);
    const [dataFrais, setDataFrais] = useState<any>(stepsDataEmprunteur["offre-emprunteur"]["frais_list"]);

    const [produits, setProduits] = useState<any[] | undefined>(setProduitsAndProduitsInitial(initDataEmprunteur)?.produits);
    const [produitsInitial] = useState<any[] | undefined>(setProduitsAndProduitsInitial(initDataEmprunteur)?.produitsInitial);
    const [value] = useState<any>({
        sm_id: initDataEmprunteur["sm_id"],
        commission: initDataEmprunteur["id_commission"] ?? "U4010",
        devis_principale_id: initDataEmprunteur["devis_principale_id"]
    });
    const [cheaperProduit, setCheaperProduit] = useState<any>(); // Le produit le moins cher


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        getTarifProcento(initDataEmprunteur, value);
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsDataEmprunteur({ ...stepsDataEmprunteur, "offre-emprunteur": data }));
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, tarif: dataTarif }));
    }, [dataTarif]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, tarif: cheaperProduit }));
    }, [cheaperProduit]);


    const getTarifProcento = async (data: any, value: any) => {
        await ParcoursService.api_get_save_frais({
            devis_principale_id: value?.devis_principale_id,
            frais_list: dataFrais
        }).then((result: any) => {
            if (result && result.hasOwnProperty("frais_list")) {
                setDataFrais(result["frais_list"]); // mis à jour des frais
            }

            // Tableau des requêtes
            let promises = [];
            for (const [, _val] of Object.entries(data.produits)) {
                value["id_produit"] = _val;
                promises.push(ParcoursService.api_get_tarif_procento(value));
            }

            // Recupération de chaque tarif
            merge(...promises).subscribe((value: any) => {
                if (value.Dossier && value.Dossier.Produit.IdProduit) {
                    // Ajout du tarif dans l'objet produit
                    let produit = produits?.find(p => p.id === value.Dossier.Produit.IdProduit);
                    let produitInitial = produitsInitial?.find(p => p.id === value.Dossier.Produit.IdProduit);
                    if (produit) {
                        // Désactiver le loading
                        produit.loading = false;
                        produitInitial.loading = false;

                        // On met à jour l'objet produit
                        Object.assign(produit, value);
                        Object.assign(produitInitial, value);

                        // Calcul total
                        calculTotal(produit);

                        // Trier les tarifs
                        const tarifs = JSON.parse(JSON.stringify(produits));

                        // Trier tarif par montant total
                        let temp_produits: any;
                        temp_produits = tarifs.sort((a: any, b: any) => {
                            let atotal = a.total === undefined ? Infinity : a.total;
                            let btotal = b.total === undefined ? Infinity : b.total;
                            return atotal - btotal;
                        });
                        setProduits(temp_produits);
                        setCheaperProduit(temp_produits.filter((p: object) => p.hasOwnProperty('Dossier'))[0]);

                        if (data.id_produit) {
                            const _selectedTarif = parseInt(data.id_produit);

                            // S'il y a déjà un produit séléctionné : on séléctionne directement ce produit
                            if (produit.Dossier.Produit.IdProduit === _selectedTarif) {
                                setDataTarif(produit);
                            }
                        }
                    }
                } else {
                    if (value.IdProduit) {
                        let produit = produits?.find((p: any) => p.id === value.IdProduit);
                        let produitInitial = produitsInitial?.find((p: any) => p.id === value.IdProduit);

                        // Désactiver le loading
                        produit.loading = false;
                        produitInitial.loading = false;

                        // On met à jour l'objet produit
                        Object.assign(produit, value);
                        Object.assign(produitInitial, value);
                    }
                }
            });
        });
    };

    const calculTotal = (tarif = null) => {
        if (tarif != null) {
            getResultCalcul(tarif);
        } else {
            produits?.forEach((produit) => {
                getResultCalcul(produit);
            });
        }
    };

    const getResultCalcul = (tarif: any) => {
        if (tarif.Assures?.length > 0) {
            // Calcul montant total pour chaque produit
            tarif.total = tarif.Assures.reduce((partialSum: any, assure: any) => {
                if (assure.Totaux) {
                    return partialSum + assure.Totaux.Montant_Total;
                }
                return partialSum;
            }, 0);
            // Calcul montant total par mois pour chaque produit
            tarif.total_mois = tarif?.Assures.reduce((partialSum: any, assure: any) => {
                if (assure.Totaux) {
                    return partialSum + assure.CoutMoyenAssurance_AvecFrais;
                }
                return partialSum;
            }, 0);
        }
    };

    const isLoadingTarifsFinished = (tarifs: any) => {
        return tarifs?.every((ta: any) => {
            return ta["loading"] === false;
        });
    };

    const isStepDataValid = () => {
        return cheaperProduit && isLoadingTarifsFinished(produits);
    };


    return (
        <Stack alignItems={"center"} justifyContent={"center"} mt={6}>
            {((): any => {
                if (currentSubstep === 0) {
                    return (
                        <>
                            <h3 className='m-0 text-center' style={{ fontSize: 25 }}>
                                Voilà l'offre recommandée pour vous
                            </h3>
                            <p className='century text-center' style={{ marginTop: 1, fontSize: 15 }}>
                                Vous pouvez personnaliser cette offre encore davantage afin de payer juste pour ce dont vous avez besoin.
                            </p>
                            {
                                currentErreurMessage ? (<Error error={currentErreurMessage} />) : (
                                    <>
                                        {((): any => {
                                            if (loading || !isLoadingTarifsFinished(produits)) {
                                                return (
                                                    (<Loading />)
                                                )
                                            }
                                            if (cheaperProduit && isLoadingTarifsFinished(produits)) {
                                                return (
                                                    <Fade in={stepEmprunteur === 3} {...({ timeout: 1500 })}>
                                                        <Stack>
                                                            <Stack className={"box-border-emp"} alignItems={"center"} justifyContent={"center"} gap={2} my={2} p={7} borderRadius={3}>
                                                                <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
                                                                    <BiSolidBank style={{ fontSize: 70, color: colors.blue1, marginBottom: 10 }} />
                                                                    <p className="century-bold m-0" style={{ color: colors.blue1 }} >
                                                                        {cheaperProduit?.nom}
                                                                    </p>
                                                                </Stack>
                                                                <Stack alignItems={"baseline"} direction={"row"} gap={0.5}>
                                                                    <h2 className='m-0'>{cheaperProduit?.total_mois.toFixed(2)}</h2>
                                                                    <p className='m-0 century'>€/mois</p>
                                                                </Stack>
                                                                <Button className={"btn-souscription"} variant="contained" onClick={() => props.goNext()}>
                                                                    Je continue ma souscription
                                                                </Button>
                                                            </Stack>
                                                            <Stack alignItems={"center"} justifyContent={"center"}>
                                                                <p className='century-bold' style={{ marginTop: 20, fontSize: 16 }}>
                                                                    Par volonté de vous offrir la meilleure expérience client possible, un conseiller Julia va vous appeler pour valider votre contrat après votre souscription.
                                                                    <br />
                                                                    Merci de votre confiance 😁 !
                                                                </p>
                                                                
                                                            </Stack>
                                                        </Stack>
                                                    </Fade>
                                                )
                                            }
                                            if (!cheaperProduit && isLoadingTarifsFinished(produits)) {
                                                return (
                                                    <Error error={"Nous n'avons pas trouvé une ofrre qui correspond à vos besoins"} />
                                                )
                                            }
                                        })()}
                                    </>
                                )
                            }
                        </>
                    )
                }
            })()}
        </Stack>
    )
};