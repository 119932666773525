/* eslint-disable react-hooks/exhaustive-deps */
import { 
    Backdrop, Box, Button, Fade, FormControl, Grid, 
    MenuItem, Modal, OutlinedInput, Select, Slide, Stack, TextField 
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import { useEffect, useState } from 'react';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsDataEmprunteur } from '../../../redux/actions';
import { PiHouseLineThin } from "react-icons/pi";
import { PiArrowBendDoubleUpRightThin } from "react-icons/pi";
import { PiFilesThin } from "react-icons/pi";
import creditAgricole from "../../../assets/images/banques/credit_agricole.png";
import caisseEpargne from "../../../assets/images/banques/caisse_epargne.png";
import banquePopulaire from "../../../assets/images/banques/banque_populaire.png";
import boursorama from "../../../assets/images/banques/boursorama.png";
import lcl from "../../../assets/images/banques/lcl.png";
import bnpParibas from "../../../assets/images/banques/bnp_paribas.png";
import banquePostale from "../../../assets/images/banques/banque_postale.png";
import societeGenerale from "../../../assets/images/banques/societe_generale.png";
import creditMutuel from "../../../assets/images/banques/credit_mutuel.png";
import cic from "../../../assets/images/banques/cic.png";
import hsbc from "../../../assets/images/banques/hsbc.png";
import fortuneo from "../../../assets/images/banques/fortuneo.png";
import arkea from "../../../assets/images/banques/arkea.png";
import creditDuNord from "../../../assets/images/banques/credit_du_nord.png";
import creditFoncier from "../../../assets/images/banques/credit_foncier.png";
import ing from "../../../assets/images/banques/ing.png";
import axa from "../../../assets/images/banques/axa.png";
import bForBank from "../../../assets/images/banques/b_for_bank.png";
import orangeBank from "../../../assets/images/banques/orange_bank.png";
import banqueMutualiste from "../../../assets/images/banques/banque_française_mutualiste.png";
import creditImmobilier from "../../../assets/images/banques/credit_immobilier.png";
import banquePlatine from "../../../assets/images/banques/banque_platine.png";
import cetelem from "../../../assets/images/banques/cetelem.png";
import federalFinance from "../../../assets/images/banques/federal_finance.png";
import helloBank from "../../../assets/images/banques/hello_bank.png";
import barclays from "../../../assets/images/banques/barclays.png";
import groupama from "../../../assets/images/banques/groupama.png";
import { PiUmbrellaThin } from "react-icons/pi";
import { PiBankThin } from "react-icons/pi";
import { PiKeyLight } from "react-icons/pi";
import colors from '../../../colors/colors';
import { HiUser, HiUserGroup, HiUsers } from 'react-icons/hi2';
import '../ParcoursEmprunteur.scss';


const replaceNullWithEmptyString = (obj: any) => {
    for (let key in obj) {
        if (obj[key] === null) {
            obj[key] = "";
        }
    }
    return obj;
};

const typeAssurances = [
    { label: "Un nouvel emprunt", icon: <PiHouseLineThin color={colors.blue1} size={70} /> },
    { label: "Un changement d'assurance", icon: <PiArrowBendDoubleUpRightThin color={colors.blue1} size={70} /> },
    { label: "Une renégociation de prêt", icon: <PiFilesThin color={colors.blue1} size={70} /> }
];

const banques = [
    { name: "Crédit Agricole", image: creditAgricole },
    { name: "Caisse d'Epargne", image: caisseEpargne },
    { name: "Banque Populaire", image: banquePopulaire },
    { name: "Boursorama", image: boursorama },
    { name: "LCL", image: lcl },
    { name: "BNP Paribas", image: bnpParibas },
    { name: "La banque Postale", image: banquePostale },
    { name: "Société Générale", image: societeGenerale },
    { name: "Crédit Mutuel", image: creditMutuel },
    { name: "CIC", image: cic },
    { name: "HSBC", image: hsbc },
    { name: "Fortuneo", image: fortuneo },
    { name: "Crédit Mutuel Arkea", image: arkea },
    { name: "Crédit du Nord", image: creditDuNord },
    { name: "Crédit Foncier", image: creditFoncier },
    { name: "ING", image: ing },
    { name: "AXA Banque", image: axa },
    { name: "BforBANK", image: bForBank },
    { name: "Orange Bank", image: orangeBank },
    { name: "Banque Française Mutualiste", image: banqueMutualiste },
    { name: "Crédit Immobilier de France", image: creditImmobilier },
    { name: "Banque Palatine", image: banquePlatine },
    { name: "Cetelem", image: cetelem },
    { name: "Federal Finance", image: federalFinance },
    { name: "Hello Bank", image: helloBank },
    { name: "Barclays", image: barclays },
    { name: "Groupama", image: groupama }
];

const objetFinancement = [
    { label: "Résidence principale", value: "Résidence principale", icon: <PiHouseLineThin color={colors.blue1} size={70} /> },
    { label: "Résidence secondaire", value: "Résidence secondaire", icon: <PiUmbrellaThin color={colors.blue1} size={70} /> },
    { label: "Investissement locatif", value: "Locatif", icon: <PiKeyLight color={colors.blue1} size={70} /> }
];

const nbrPersons = [
    { label: "Une seule personne", icon: <HiUser color={colors.blue1} size={70} /> },
    { label: "Vous et votre co-emprunteur", icon: <HiUsers color={colors.blue1} size={70} /> },
    { label: "Plus de deux personnes", icon: <HiUserGroup color={colors.blue1} size={70} /> }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    p: 4,
    outline: "none !important"
};

export default function InfosBien(props: any) {
    const dispatch = useDispatch();
    const stepEmprunteur = useSelector((store: any) => store.stepEmprunteur.stepEmprunteur);
    const currentSubstep = useSelector((store: any) => store.currentSubstep.currentSubstep);
    const initDataEmprunteur = useSelector((store: any) => store.initDataEmprunteur.initDataEmprunteur);
    const stepsDataEmprunteur = useSelector((store: any) => store.stepsDataEmprunteur.stepsDataEmprunteur);
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);

    const [data, setData] = useState<any>(stepsDataEmprunteur["informations-bien"]);
    const [dataOrganisme, setDataOrganisme] = useState<any>(replaceNullWithEmptyString(stepsDataEmprunteur["informations-bien"]["organisme"]));
    const [showEmprunteurList, setShowEmprunteurList] = useState(stepsDataEmprunteur["informations-bien"]["nb_adherents"] > 2);
    const [lstPays] = useState<any>(initDataEmprunteur["lst_pays"]);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsDataEmprunteur({ ...stepsDataEmprunteur, "informations-bien": data }));
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [data]);

    useEffect(() => {
        setData((prevState: any) => ({ ...prevState, organisme: dataOrganisme }));
    }, [dataOrganisme]);

    useEffect(() => {
        if (isStepDataValid()) {
            dispatch(changeIsButtonEnabled(true));
        } else {
            dispatch(changeIsButtonEnabled(false));
        }
    }, [currentSubstep]);


    const setStepData = (key: string, value: any) => {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleDataOrganisme = (key: string, value: any) => {
        setDataOrganisme((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSelectEmprunteur = (index: number) => {
        // Sup 2 adherents
        if (index === 3) {
            setShowEmprunteurList(true);
            setStepData("nb_adherents", 0);
        }
        else {
            setShowEmprunteurList(false);
            setStepData("nb_adherents", index);
            // props.goNext();
        }
    };

    const handleNumberPersonne = (index: number) => {
        setStepData("nb_adherents", index);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isOtherBank = () => dataOrganisme?.nom && !banques.some(banque => banque.name === dataOrganisme?.nom);

    const isOrganismeDataValid = () => {
        return dataOrganisme?.nom && dataOrganisme?.pays
    };

    const isStepDataValid = () => {
        if (currentSubstep === 0) {
            return data["type_assurance"] !== "";
        }
        if (currentSubstep === 1) {
            return isOrganismeDataValid();
        }
        if (currentSubstep === 2) {
            return data["objet_du_financement"];
        }
        if (currentSubstep === 3) {
            return data["nb_adherents"] > 0;
        }
    };


    return (
        <Stack spacing={2} mt={6}>
            {currentErreurMessage && (<Error error={currentErreurMessage} />)}
            {((): any => {
                if (currentSubstep === 0) {
                    return (
                        <Slide in={stepEmprunteur === 0} {...({ timeout: 1000 })} direction='left'>
                            <Stack spacing={4}>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Vous souhaitez une assurance pour :
                                </h3>
                                <Stack width={"100%"}>
                                    <Grid container spacing={2}>
                                        {
                                            typeAssurances.map((as, index) => (
                                                <Grid container item md={4} xs={12} justifyContent={"center"} key={index}>
                                                    <Stack borderRadius={5} key={index} width={"100%"} alignItems={"center"} justifyContent={"center"} p={3}
                                                        className={as.label === data["type_assurance"] ? "card-assure-emp-blue" : "card-assure-emp"}
                                                        onClick={() => { setStepData("type_assurance", as.label); props.goNext() }}
                                                    >
                                                        {as.icon}
                                                        <p className="text-center century-bold" style={{ fontSize: 16 }}>
                                                            {as.label}
                                                        </p>
                                                    </Stack>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Slide>
                    )
                }
                if (currentSubstep === 1) {
                    return (
                        <Slide in={stepEmprunteur === 0} {...({ timeout: 1000 })} direction='left'>
                            <Stack spacing={4}>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Banque sollicitée ?
                                </h3>
                                <p className='century' style={{ marginTop: 1, fontSize: 15 }}>
                                    Sélectionnez bien votre banque prêteuse, les exigences de garantie peuvent changer d’une banque à l’autre !
                                    Cela impactera donc le tarif de votre assurance de prêt.
                                </p>
                                <Stack width={"100%"}>
                                    <Grid container spacing={2}>
                                        {
                                            banques?.map((bk: any, index: number) => (
                                                <Grid container item md={3} xs={6} key={index} onClick={() => handleDataOrganisme("nom", bk.name)}>
                                                    <Stack onClick={() => { handleDataOrganisme("nom", bk.label); props.goNext() }} width={"100%"} className={`${dataOrganisme?.nom === bk.name ? "card-bank-emp-blue" : "card-bank-emp"}`} borderRadius={5}>
                                                        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} width={"100%"} py={1}>
                                                            <Box component="img" src={bk.image} alt={bk.name} width={150} />
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            ))
                                        }
                                        <Grid container item md={3} xs={6} onClick={handleOpen}>
                                            <Stack width={"100%"} className={`${isOtherBank() ? "card-bank-emp-blue" : "card-bank-emp"}`} borderRadius={5} justifyContent={"center"}>
                                                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} width={"100%"} py={1}>
                                                    <Box component="p">
                                                        <span className='century-bold' style={{ fontSize: 15 }}>Autre banque</span>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Slide>
                    )
                }
                if (currentSubstep === 2) {
                    return (
                        <Slide in={stepEmprunteur === 0} {...({ timeout: 1000 })} direction='left'>
                            <Stack spacing={4}>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Objet du prêt
                                </h3>
                                <Stack width={"100%"}>
                                    <Grid container spacing={2}>
                                        {
                                            objetFinancement.map((as, index) => (
                                                <Grid container item md={4} xs={12} justifyContent={"center"} key={index}>
                                                    <Stack borderRadius={5} key={index} width={"100%"} alignItems={"center"} justifyContent={"center"} p={3}
                                                        className={as.value === data["objet_du_financement"] ? "card-assure-emp-blue" : "card-assure-emp"}
                                                        onClick={() => { setStepData("objet_du_financement", as.value); props.goNext() }}
                                                    >
                                                        {as.icon}
                                                        <p className="text-center century-bold" style={{ fontSize: 16 }}>
                                                            {as.label}
                                                        </p>
                                                    </Stack>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Slide>
                    )
                }
                if (currentSubstep === 3) {
                    return (
                        <Slide in={stepEmprunteur === 0} {...({ timeout: 1000 })} direction='left'>
                            <Stack spacing={4}>
                                <h3 className='m-0' style={{ fontSize: 25 }}>
                                    Personne(s) à assurer
                                </h3>
                                <Stack width={"100%"}>
                                    <Grid container spacing={2}>
                                        {
                                            nbrPersons.map((as, index) => (
                                                <Grid container item md={4} xs={12} justifyContent={"center"} key={index} onClick={() => handleSelectEmprunteur(index + 1)}>
                                                    <Stack borderRadius={5} key={index} width={"100%"} alignItems={"center"} justifyContent={"center"} p={3}
                                                        className={(index + 1 === data["nb_adherents"]) || (index === 2 && showEmprunteurList) ? "card-assure-emp-blue" : "card-assure-emp"}
                                                    >
                                                        {as.icon}
                                                        <p className="text-center century-bold" style={{ fontSize: 16 }}>
                                                            {as.label}
                                                        </p>
                                                    </Stack>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    {
                                        showEmprunteurList && (
                                            <Fade in={showEmprunteurList} {...({ timeout: 1500 })}>
                                                <Stack mt={3}>
                                                    <h3 className='m-0' style={{ fontSize: 25 }}>
                                                        Choisissez le nombre exact de personnes :
                                                    </h3>
                                                    <Grid container spacing={2} mt={2}>
                                                        {
                                                            Array.from({ length: 2 }, (_, index) => (
                                                                <Grid container item md={3} xs={6} key={index} onClick={() => handleNumberPersonne(index + 3)}>
                                                                    <Stack width={"100%"} py={5}
                                                                        className={`${data["nb_adherents"] === index + 3 ? "card-assure-emp-blue" : "card-assure-emp"}`}
                                                                        borderRadius={5}>
                                                                        <h2 className='m-0 text-center text-blue-1'>{index + 3}</h2>
                                                                    </Stack>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Stack>
                                            </Fade>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </Slide>
                    )
                }
            })()}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 1000,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} width={{ md: 400, xs: "unset" }}>
                        <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
                            <PiBankThin style={{ fontSize: 70, color: colors.blue1 }} />
                            <p className="text-bold m-0 century" >Autre banque</p>
                        </Stack>
                        <Stack spacing={2} mt={2}>
                            <FormControl fullWidth required>
                                <Label label={"Nom de la banque"} fontFamily={"century-bold"} />
                                <TextField className='input-emp' onChange={(event: any) => handleDataOrganisme("nom", event.target.value)} variant="outlined" value={isOtherBank() ? dataOrganisme?.nom : ""} />
                            </FormControl>
                            <FormControl fullWidth required>
                                <Label label={"Pays"} fontFamily={"century-bold"} />
                                <Select
                                    className='input-emp'
                                    onChange={(event: any) => handleDataOrganisme("pays", event.target.value)}
                                    input={<OutlinedInput value={dataOrganisme?.pays} />}
                                >
                                    {
                                        lstPays?.map((pn: any, index: number) => (
                                            <MenuItem value={pn} key={index}>{pn}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button className="btn-precedent-emp" onClick={handleClose} variant="text">
                                    ANNULER
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    className={isOrganismeDataValid() ? "btn-suivant-emp" : "disabled-btn-emp"} disabled={!isOrganismeDataValid()}
                                    onClick={() => { handleClose(); props.goNext() }} variant="contained">
                                    VALIDER
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Fade>
            </Modal >
        </Stack >
    )
};