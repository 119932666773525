import { Container } from '@mui/material';
import colors from '../../colors/colors';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import { Link } from 'react-router-dom';
import './MentionsLegales.scss';


const MentionsLegales = () => {
    return (
        <div className={"cgu"} style={{ overflowX: "hidden" }}>
            <Container maxWidth={false} sx={{ py: 12, bgcolor: colors.dark, mt: 12 }}>
                <h1 className='h1-white asar align-center'>MENTIONS LÉGALES</h1>
            </Container>
            <CustomContainer>
                <div style={{ paddingTop: 80, paddingBottom: 50 }}>
                    <p><b>Dernière modification: 13 juillet 2023</b></p>

                    <h2>Présentation du site</h2>
                    <p>En vertu de l’article 6 de la
                        loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique,
                        il est précisé aux utilisateurs du site <a className="link" target="_blank" href="https://julia-assurance.fr" rel='noreferrer'>https://julia-assurance.fr </a>
                        (ci-après le « Site ») l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi
                    </p>

                    <h2>Éditeur: Julia</h2>

                    <p>Courtier en assurances, SAS au capital de 5000 €</p>
                    <p>Siège social: 30/32 Boulevard de Vaugirard, 75015 Paris</p>
                    <p>Immatriculée au RCS de Créteil sous le numéro 881 435 358</p>
                    <p>Inscription à l’Organisme pour le Registre des Intermédiaires en Assurances (Orias) sous le numéro 20002363 –
                        <a className="link" target="_blank" href="http://www.orias.fr" rel='noreferrer'> www.orias.fr</a>
                    </p>
                    <p>Relevant du contrôle de l’ACPR (Autorité de Contrôle Prudentiel et de Résolution) – 4 place de Budapest, CS 92459 75436 PARIS CEDEX 09
                        <a className="link" target="_blank" href="http://acpr.banque-france.fr" rel='noreferrer'> acpr.banque-france.fr</a>
                    </p>

                    <h2>Directeur de publication</h2>
                    <p>Benjamin Dresner </p>

                    <h2>Contact</h2>
                    <p><b>contact: </b> <a className="link" href="mailto:contact@julia-assurance.fr">contact@julia-assurance.fr</a></p>
                    <p><b>Tel: </b> 01.86.76.72.91 </p>
                    <p><b>Conception et réalisation: </b> Antoine Joret</p>
                    <p><b>Webmaster: </b> Antoine Joret </p>

                    <h2>Hébergeur</h2>
                    <p>Heroku, Inc. 650 7th Street San Francisco, CA 94103, USA</p>
                    <p><b>URL du site: </b>www.heroku.com</p>
                    <p><b>Contact: </b>www.heroku.com/contact</p>

                    <h2>Conditions Générales d’Utilisation du Site</h2>
                    <p>L’utilisation du Site implique l’acceptation pleine et entière des présentes conditions générales d’utilisation. Ces conditions générales d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du Site sont donc invités à les consulter de manière régulière.</p>
                    <p>Le Site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique ou pour toute autre raison peut être toutefois décidée par l’Editeur, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>
                    <p>Le Site est mis à jour régulièrement. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
                    <p>Dans le cadre des présentes, le terme « Utilisateur » désigne toute personne accédant au Site.</p>

                    <h2>Description des services fournis</h2>
                    <p>Le Site a pour objet de fournir une information concernant l’ensemble des activités de l’Éditeur.
                        L’Éditeur s’efforcera de fournir des informations aussi précises que possible. Toutefois, l’Editeur ne pourra être tenu pour responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
                    <p>Toutes les informations figurant sur le Site sont données à titre indicatif. Par conséquent, elles ne sont pas contractuelles et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le Site ne sont pas exhaustifs.</p>

                    <h2>Propriété intellectuelle et contrefaçons</h2>
                    <p>Les photographies, textes, slogans, dessins, images, vidéos, séquences animées sonores ou non ainsi que toutes œuvres intégrées dans ce Site sont la propriété de l’Éditeur et/ou de ses partenaires et/ou  tiers ayant autorisé l’Éditeur à les utiliser.</p>
                    <p>Les logos, icônes et puces graphiques représentés sur le Site sont protégés au titre des droits d’auteur et des articles L.511.1 et suivants du Code de la Propriété Intellectuelle relatifs à la protection des modèles déposés.</p>
                    <p>Toute reproduction, représentation, utilisation ou modification, par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie de ce Site, de tout ou partie des différentes œuvres qui le composent, sans avoir obtenu l’autorisation préalable de l’Éditeur est strictement interdite et constitue un délit de contrefaçon et peut donner suite à des poursuites judiciaires civiles et/ou pénales et au paiement de dommages et intérêts.</p>
                    <p>Le fait pour l’Editeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>

                    <h2>Limitation de responsabilité</h2>
                    <p>La responsabilité de l’Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement empêchant l’accès au Site ou à une de ses fonctionnalités.L’Éditeur ne pourra être tenu pour responsable des dommages de quelque nature qu’ils soient causés au matériel de l’Utilisateur, lors de l’accès  au Site et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications techniques du Site, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
                    <p>Il est précisé que le Site utilise la technologie JavaScript. L’Éditeur ne pourra être tenu pour responsable de dommages liés à l’utilisation de cette technologie. De plus, l’Utilisateur du Site s’engage à accéder au Site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p>
                    <p>L’Éditeur ne pourra en aucun cas être tenu pour responsable de tout dommage de quelque nature qu’il soit résultant de l’interprétation ou de l’utilisation des informations et/ou documents disponibles sur ce Site.</p>
                    <p>L’Éditeur décline toute responsabilité quant au contenu des sites proposés en lien avec ce Site. Quelque soit le type de lien établi à partir d’un site internet extérieur, dit « site liant », vers celui de l’Éditeur, ce dernier se réserve le droit de s’opposer à son établissement.</p>
                    <p>Des espaces interactifs (possibilité de poser des questions dans l’espace «contact ») peuvent être mis à la disposition des Utilisateurs. L’Editeur se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, L’Editeur se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’Utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).</p>

                    <h2>Politique de protection des données personnelles</h2>
                    <p>Les Utilisateurs communiquent sur ce Site des données à caractère personnel les concernant  et /ou des données à caractère personnel concernant toute personne physique collectées de manière loyale et licite (cf. article 6 du Règlement Général sur la Protection des Données du 27 avril 2016).</p>
                    <p>Ces données à caractère personnel font l’objet de traitement, automatisé ou non, au sens de loi n°78-17 du 6 janvier 1978 modifiée, dite loi informatique et libertés et au Règlement Général sur la Protection des Données du 27 avril 2016.</p>
                    <p>Julia a pour objectif d’informer les Utilisateurs sur les engagements et mesures pris afin de veiller à la protection des données à caractère personnel. Pour prendre connaissance de ces engagement et mesures ainsi que vos droits, veuillez consulter notre <Link to="/protection-des-donnees" className='link' >Politique de protection des données personnelles</Link>.</p>

                    <h2>Politique de sécurité des données</h2>
                    <p>L’Éditeur met en œuvre des mesures de sécurité adaptées au degré de sensibilité des données personnelles pour les protéger contre toute intrusion malveillante, toute perte, altération ou divulgation à des tiers non autorisés. L’Editeur délivre des autorisations d’accès à son système d’information aux seules personnes qui en ont besoin pour exercer leur fonction. L’ensemble des collaborateurs sont sensibilisés à la protection des données personnelles mises à leur disposition dans le cadre de leurs fonctions et l’Editeur s’assure qu’ils respectent les règles en vigueur et la déontologie de l’entreprise. Ces principes de sécurité sont imposés aux prestataires.</p>


                    <h2>Réclamations et Médiation</h2>
                    <p>Julia a la volonté de vous apporter en permanence la meilleure qualité de service. Toutefois, des mécontentements peuvent survenir au cours de notre relation.</p>
                    <p><b>Qu’est-ce qu’une réclamation ?</b> <br />
                        Une réclamation est une déclaration actant le mécontentement d’un client envers un professionnel. Une demande de service ou de prestation, une demande d’information, de clarification ou une demande d’avis n’est pas une réclamation.</p>
                    <p><b>Comment nous transmettre une réclamation ?</b> <br />
                        Vous avez la possibilité de nous faire part de toute réclamation en vous adressant à votre interlocuteur habituel, notamment votre chargé de clientèle en lui faisant part de votre mécontentement par tout moyen à votre convenance :  par mail,  par courrier…</p>
                    <p>Vous avez aussi la possibilité de nous faire part de toute réclamation en utilisant les coordonnées suivantes : <br />
                        <b>par courrier </b>à l’adresse suivante: Julia 143 Boulevard René Cassin - Immeuble Nouvel’R - Bat C - CS 63278 - 06205 Nice CEDEX 3<br />
                        <b>par courriel </b>à: <a className="link" href="mailto:contact@julia-assurance.fr">contact@julia-assurance.fr</a></p>
                    <p>Nous nous engageons à accuser réception de votre réclamation dans un délai maximum de 10 jours ouvrables à compter de sa réception.</p>
                    <p>Nous nous engageons à vous apporter une réponse (positive ou négative) dans un délai de deux mois maximum à compter de la réception de la totalité des éléments permettant de traiter votre réclamation.</p>
                    <p><b>Notre réponse à votre réclamation ne vous satisfait pas ?</b><br />
                        Si malgré les démarches amiables le désaccord persiste, vous avez la possibilité de réaliser une demande de recours auprès d’un service distinct, la Direction Juridique, afin qu’elle réétudie votre demande en écrivant à l’adresse suivante :<br />
                        Julia <br />143 Boulevard René Cassin - Immeuble Nouvel’R - Bat C - CS 63278 <br />06205 Nice CEDEX 3<br /><br />
                        Ou par courriel : <a className="link" href="mailto:contact@julia-assurance.fr">contact@julia-assurance.fr</a><br />
                    </p>
                    <p>Pour que votre demande puisse être prise en compte, vous devez nous fournir les échanges et les pièces relatives à votre réclamation initiale ainsi que toutes les pièces que vous jugerez utiles à l’analyse du dossier.</p>
                    <p>Les documents en lien avec l’objet de votre réclamation sont conservés pendant 5 ans à compter de l’accusé de réception de votre réclamation.</p>

                    <p><b>Recours à la Médiation</b></p>
                    <p>Si la réponse apportée par nos services est jugée insatisfaisante et après avoir saisi successivement les différents services conformément à notre circuit de traitement des réclamations, en tant que particulier vous avez la possibilité de saisir le médiateur compétent.
                        Conformément aux dispositions de l’article L 616-1 du code de la consommation, le Médiateur est compétent pour intervenir dès lors qu’un litige n’a pas pu être réglé dans le cadre d’une réclamation préalable directement introduite auprès des services de Julia.</p>
                    <p>Le Médiateur exerce sa mission en toute indépendance. Ce recours est gratuit et pour rendre ses conclusions, il a libre accès au dossier</p>
                    <p>Les coordonnées du médiateur sont les suivantes : <br />
                        <b>Courrier:</b> Médiation de l’assurance TSA 50110 75441 Paris Cedex 09<br />
                        <b>Mail:</b> <a className="link" href="mailto:le.mediateur@mediation-assurance.org"> le.mediateur@mediation-assurance.org</a><br />
                        <b>Adresse du site internet: </b> <a className="link" target="_blank" href="www.mediation-assurance.org">www.mediation-assurance.org</a>
                    </p>
                    <h2>Liens Hypertextes</h2>
                    <p>Ce Site peut contenir des liens hypertextes vers d’autres sites internet et d’autres sources informatiques mis en place avec l’autorisation expresse et préalable de l’Éditeur, édités et gérés par des sites partenaires commerciaux ou non commerciaux.</p>
                    <p>Les sites auxquels ces liens permettent d’accéder, indépendants  du Site de l’Éditeur, sont proposés par ces sociétés, sous leur entière responsabilité. D’une manière générale l’Éditeur n’exerçant aucun contrôle sur ces sites ne saurait être tenu pour responsable de leur disponibilité, contenu, offres, informations ou des transactions réalisées sur ces derniers.
                    </p>

                    <h2>Droit applicable et attribution de juridiction</h2>
                    <p>Tout litige en relation avec l’utilisation du Site est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>

                    <h2>Contact</h2>
                    <p>Pour toute question ou information concernant le Site, l’utilisateur peut laisser un message à l’adresse suivante :
                        <a className="link" href="mailto:contact@julia-assurance.fr">contact@julia-assurance.fr</a>
                    </p>
                </div>
            </CustomContainer>
        </div>
    );
};


export default MentionsLegales;