import { Stack, Box, Avatar } from '@mui/material';
import { Container } from '@mui/system';
import starIcon from "../../assets/icons/star.svg";
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import StarRateIcon from '@mui/icons-material/StarRate';
import './SatisfactionClients.scss';


const avis = [
    {
        initial: "K",
        nombre_avis: 2,
        nombre_photo: null,
        color: "#a54931",
        nom: "KAMEL IMEKRAZ",
        remarque: `
            Je tiens à vous dire que je suis très content, je viens de prendre une mutuelle chez JULIA ASSURANCE grâce à Mme Ambre WEIXLER qui est formidable, professionnelle, réactive, très sympathique, qui cherche le meilleur pour son client et pour terminer HONNETE. 
            Merci Ambre et à bientôt !
            M.IMEKRAZ Kamel
        `,
        visited: "septembre"
    },
    {
        initial: "C",
        nombre_avis: 1,
        nombre_photo: null,
        color: "#564239",
        nom: "Céline Rivière",
        remarque: `
            Très grande réactivité. Accueil téléphonique top et très professionnel. 
            Un vrai travail pour trouver le contrat le plus approprié à mes besoins pour le meilleur prix/besoin
        `,
        visited: "juillet"
    },
    {
        initial: "J",
        nombre_avis: 15,
        nombre_photo: 16,
        color: "#496430",
        nom: "Jacqueline Lamothe",
        remarque: `
            J'étais à la recherche d'une bonne mutuelle à prix raisonnable et j'ai été très bien accompagné par Julia assurance. 
            Très professionnel et extrêmement réactif, on arrive toujours à les joindre et on a toujours des réponses adaptées et précises. 
            Je recommande vivement
        `,
        visited: "juin"
    }
];


function SatisfactionClients() {
    const childContainerStyles = {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        py: 4,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const childStyles = {
        flex: '0 0 auto',
        maxWidth: '400px',
        minWidth: '320px'
    };

    return (
        <Container className={"px-0 half-background-emprunteur"} maxWidth={false} sx={{ py: 10, mb: 4 }}>
            <CustomContainer>
                <Stack width={{ md: "60%", xs: "100%" }} spacing={5}>
                    <h2 className='h2-white'>Nos clients sont satisfaits, ce sont eux qui le disent ! 😉</h2>
                    <p style={{ color: "white" }}>Julia Assurance obtient une note de 4,9 étoiles sur Google.</p>
                    <Stack direction={"row"} spacing={{ md: 3, xs: "unset" }}>
                        {
                            Array.from({ length: 5 }, (_, index) => (
                                <small key={index}>
                                    <Box component="img" pb={2.5} src={starIcon} alt="star icon" />
                                </small>
                            ))
                        }
                    </Stack>
                </Stack>
                <Stack direction={"row"} spacing={6} mt={{ md: 5, xs: 0 }} sx={childContainerStyles} ml={{ md: 5, xs: 0 }} className="hidden-scroll">
                    {
                        avis.map((av, index) => (
                            <Box p={2} style={{ ...childStyles }} key={index} bgcolor={"white"} width={"40%"} borderRadius={3} boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19)"}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Avatar sx={{ bgcolor: av.color }}><span style={{ color: "white", verticalAlign: "middle" }}>{av.initial}</span></Avatar>
                                    <Stack direction={"column"}>
                                        <p className='m-0 century-bold'>{av.nom}</p>
                                        <small className='small'>{av.nombre_avis} avis {av.nombre_photo && (<span> . {av.nombre_photo} photos</span>)}</small>
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} alignItems={"start"} spacing={1} mt={1} gap={1.5}>
                                    <Stack direction={"row"}>
                                        {
                                            Array.from({ length: 5 }, (_, index) => (
                                                <small key={index}>
                                                    <StarRateIcon sx={{ color: "#faba00", fontSize: 20 }} />
                                                </small>
                                            ))
                                        }
                                    </Stack>
                                    <p className='m-0'>
                                        <span className='century-bold'>Aspects positifs : </span>
                                        <span className='small'>Professionnalisme, Qualité, Rapport qualité-prix, Réactivité</span>
                                    </p>
                                </Stack>
                                <p className='century' style={{ fontSize: 17 }}>{av.remarque}</p>
                                <small className='small'>Visité en {av.visited}</small>
                            </Box>
                        ))
                    }
                </Stack>
            </CustomContainer>
        </Container>
    );
};


export default SatisfactionClients;