/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button, Toolbar, AppBar, IconButton, Stack, Avatar } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { scroller } from 'react-scroll';
import { ElevationScroll } from "../../utils/scrolls";
import logoJulia from "../../assets/logos/Logo_RVB.svg";
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { FaPhoneAlt } from "react-icons/fa";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from "react-router-dom";
import { CustomContainer } from '../CustomContainer/CustomContainer';
import ParcoursService, { AdminService } from '../../services/services';
import { changeLoading, changeMember, changePanier, changePanierGlobal, changeShowPanier } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import colors from "../../colors/colors";
import { IoMdStar } from "react-icons/io";
import { grey, indigo } from '@mui/material/colors';
import './Header.scss';


const sections = [
    { name: "Complémentaire santé", path: "/sante" },
    { name: "Assurance de prêt", path: "/emprunteur" },
    { name: "Qui sommes-nous ?", path: "/about" },
    { name: "Nous rejoindre", path: "/join" }
];


const Header = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pathname = location.pathname;
    const [open, setOpen] = useState<boolean>(false);
    const product = "DevisSanteClient";

    const nom = queryParams.get('nom');
    const email = queryParams.get('email');
    const prenom = queryParams.get('prenom');

    const [isBranding, setIsBranding] = useState<null | string | boolean>(queryParams.get('branding'));
    const [dataHomepage, setDataHomepage] = useState<any>({});


    useEffect(() => {
        window.scrollTo(0, 0);
        if (isBranding) {
            getHomePage();
        }
    }, [pathname]);


    const getHomePage = async () => {
        await AdminService.api_get_home_page(
            `${location.pathname}?branding=${isBranding?.toString().toLowerCase()}`
        ).then((data: any) => {
            if (data['erreur']) {
                setIsBranding(false);
            } else {
                setDataHomepage(data);
            }
        }).catch(() => {
            setIsBranding(false);
        });
    };


    const getPanier = async () => {
        await ParcoursService.api_get_panier('', product).then((data: any) => {
            dispatch(changePanier(data['success']['devis_active']));
            dispatch(changePanierGlobal(data['success']));
            dispatch(changeMember(data['success']['global']));
            dispatch(changeShowPanier(true));
        }).catch(() => {
            dispatch(changeLoading(false));
        });
    };

    const scrollTo = (sectionId: string) => {
        if (pathname.includes("join")) {
            navigate('/', { state: { isFromJoin: true } });
        } else {
            scroller.scrollTo(sectionId, {
                duration: 1000,
                offset: -150,
                smooth: true,
                spy: true
            });
        }
    };


    return (
        <>
            {(pathname !== '/document' && !location.pathname.includes("signature") && !queryParams.get('partenaire')) && (
                <ElevationScroll {...props}>
                    <AppBar className="header" position="fixed" sx={{ py: 2 }} >
                        {
                            !open ? (
                                <CustomContainer>
                                    {
                                        (pathname === '/document-upload') && (
                                            <Toolbar disableGutters>
                                                {/* Header for document */}
                                                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }} width={"100%"}>
                                                    <Box onClick={() => { !isBranding ? navigate("/") : window.open(dataHomepage["redirect_url"]) }}
                                                        component="img" src={!isBranding ? logoJulia : dataHomepage["logo"]} alt="logo Julia" width={120}
                                                        sx={{ mr: 1, cursor: "pointer" }}
                                                    />
                                                    <Stack justifyContent={"end"} alignItems={"start"} display={{ xs: "none", md: "flex" }}>
                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                            <Avatar sx={{ bgcolor: indigo[700] }} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{prenom?.split("")[0]}{nom?.split("")[0]}</Avatar>
                                                            <Stack display={{ xs: "none", md: "flex" }}>
                                                                <p className='p-bold' style={{ textTransform: 'capitalize', margin: 0, padding: 0, color: grey[600] }}>{prenom} {nom}</p>
                                                                <p className='p-bold' style={{ margin: 0, padding: 0, fontSize: 14, color: grey[500] }}>{email}</p>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>

                                                {/* Header for mobile */}
                                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "end" }}>
                                                    <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => setOpen(true)}>
                                                        <Avatar sx={{ bgcolor: indigo[700] }} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{prenom?.split("")[0]}{nom?.split("")[0]}</Avatar>
                                                    </IconButton>
                                                </Box>
                                            </Toolbar>
                                        )
                                    }

                                    {
                                        !pathname.includes('document') && (
                                            <Toolbar disableGutters>
                                                {/* Header for desktop (On affiche pas le logo si on est sur les pages devis-simple donc none in display)*/}
                                                <Box onClick={() => { navigate("/") }} component="img" src={logoJulia} alt="logo Julia" width={120}
                                                    sx={{ display: { xs: 'none', md: (pathname === "/devis-sante-simple" || pathname === "/devis-emprunteur-simple" || !["", undefined].includes(pathname.split("/")[2]) || isBranding) ? "none" : "flex" }, mr: 1, cursor: "pointer" }}
                                                />
                                                {
                                                    !pathname.includes("devis") && !pathname.includes("mentions-legales") && !pathname.includes("protection-des-donnees") && !pathname.includes("bienvenue") && (
                                                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "end", alignItems: "center" }} gap={3}>
                                                            {sections.map((page, key) => (
                                                                <Box key={key} onClick={() => { navigate(page.path) }} className={`header-item ${pathname.includes(page.path) ? "header-item-active" : ""}`} component="h4" my={2}>
                                                                    {page.name}
                                                                </Box>
                                                            ))}
                                                            <Button className="nous-contacter" onClick={() => scrollTo("contact")} variant="contained" startIcon={<MailIcon />}>
                                                                Nous contacter
                                                            </Button>
                                                        </Box>
                                                    )
                                                }

                                                {/* Header for devis-sante-simple and devis-emprunteur-simple */}
                                                {
                                                    (pathname === "/devis-sante-simple" || pathname === "/devis-emprunteur-simple" || !["", undefined].includes(pathname.split("/")[2]) || isBranding) && (
                                                        <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }} width={"100%"}>
                                                            <Box onClick={() => { !isBranding ? navigate("/") : window.open(dataHomepage["redirect_url"]) }}
                                                                component="img" src={!isBranding ? logoJulia : dataHomepage["logo"]} alt="logo Julia" width={120}
                                                                sx={{ mr: 1, cursor: "pointer" }}
                                                            />
                                                            <Stack justifyContent={"center"} alignItems={"center"} display={{ xs: "none", md: "flex" }}>
                                                                <Stack gap={1} direction={"row"} alignItems={"center"}>
                                                                    <FaPhoneAlt color={!isBranding ? colors.blue1 : dataHomepage["primary_color"]} size={20} />
                                                                    <p className={`m-0 century-bold`} style={{ fontSize: 25, color: isBranding ? dataHomepage["primary_color"] : colors.blue1 }}>
                                                                        01 86 76 72 91
                                                                    </p>
                                                                </Stack>
                                                                <p className='m-0 century' style={{ fontSize: 16.5 }}>Besoin d'un conseil ? Appelez nos experts</p>
                                                                <small className='century-bold' style={{ fontSize: 12, color: "#999" }}>* Du lundi au vendredi 10h - 19h. Appel non surtaxé</small>
                                                            </Stack>
                                                            <Stack justifyContent={"end"} alignItems={"start"} display={{ xs: "none", md: "flex" }}>
                                                                <p className='century-bold m-0' style={{ fontSize: 15 }}>Avis Google</p>
                                                                <Stack direction={"row"} ml={-0.5}>
                                                                    {Array.from({ length: 5 }, (_, index) => (
                                                                        <IoMdStar key={index} color={colors.green} size={22} />
                                                                    ))}
                                                                </Stack>
                                                                <p className='century m-0' style={{ fontSize: 15 }}>Note <strong>4.9</strong></p>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                }

                                                {/* Header for mobile */}
                                                <Box onClick={() => { navigate("/") }} component="img" src={logoJulia} alt="Hero" width={100}
                                                    sx={{ display: { xs: (pathname === "/devis-sante-simple" || pathname === "/devis-emprunteur-simple" || !["", undefined].includes(pathname.split("/")[2]) || isBranding) ? "none" : "flex", md: 'none' }, mr: 1, cursor: "pointer" }}
                                                />
                                                {
                                                    !pathname.includes("devis") && !pathname.includes("mentions-legales") && !pathname.includes("protection-des-donnees") && !pathname.includes("bienvenue") && (
                                                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "end" }}>
                                                            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => setOpen(true)}>
                                                                <MenuIcon sx={{ color: "#272D37" }} />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                }

                                                {/* Show this when in /devis-sante page */}
                                                {
                                                    pathname === "/devis-sante" && (
                                                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "end", alignItems: "center" }} >
                                                            <IconButton size="large" aria-controls="menu-appbar" onClick={() => getPanier()}>
                                                                <ShoppingCartIcon sx={{ color: "#272D37" }} />
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                }
                                            </Toolbar>
                                        )
                                    }
                                </CustomContainer>
                            ) : (
                                (pathname !== '/document-upload') ? (
                                    <CustomContainer>
                                        <Stack direction={"column"} width={"100%"} alignItems={"end"}>
                                            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => setOpen(false)}>
                                                <CloseIcon sx={{ color: "#272D37" }} />
                                            </IconButton>
                                            {sections.map((page, key) => (
                                                <MenuItem key={key}>
                                                    <Box className={`header-item m-0 ${pathname.includes(page.path) ? "header-item-active-mobile" : ""}`} onClick={() => { setOpen(false); navigate(page.path) }} component="h4" py={0.5}>
                                                        {page.name}
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Stack>
                                        <Stack width={"100%"} alignItems={"center"} direction={"column"} mb={1}>
                                            <Button className="nous-contacter" onClick={() => scrollTo("contact")} variant="contained" startIcon={<MailIcon />} sx={{ mt: 1 }} fullWidth>
                                                Nous contacter
                                            </Button>
                                        </Stack>
                                    </CustomContainer>
                                ) : (
                                    <CustomContainer>
                                        <Stack direction={"column"} width={"100%"} alignItems={"end"}>
                                            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => setOpen(false)}>
                                                <CloseIcon sx={{ color: "#272D37" }} />
                                            </IconButton>
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Avatar sx={{ bgcolor: indigo[700] }} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{prenom?.split("")[0]}{nom?.split("")[0]}</Avatar>
                                                <Stack display={{ md: "flex" }}>
                                                    <p className='p-bold' style={{ textTransform: 'capitalize', margin: 0, padding: 0, color: grey[600] }}>{prenom} {nom}</p>
                                                    <p className='p-bold' style={{ margin: 0, padding: 0, fontSize: 14, color: grey[500] }}>{email}</p>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </CustomContainer>
                                )
                            )
                        }
                    </AppBar>
                </ElevationScroll >
            )}
        </>
    );
};


export default Header;