/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Stack, FormControlLabel, TextField, FormControl, Checkbox, MenuItem } from '@mui/material';
import Label from '../../Label/Label';
import Error from '../../Error/Error';
import { changeCurrentErrorMessage, changeIsButtonEnabled, changeStepsData } from '../../../redux/actions';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../ParcoursSante.scss';


const codePostalRegex = new RegExp('^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$');
const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');


export default function CoordonneesSante() {
    const dispatch = useDispatch();
    const currentErreurMessage = useSelector((store: any) => store.currentErreurMessage.currentErreurMessage);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const [data, setData] = useState<any>(stepsData["coordonees-sante"]);


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(false));
        removeConjointAndEnfantKey();
        const inputElement = document.querySelector('.google-places-autocomplete__input');
        if (inputElement) {
            inputElement.addEventListener('input', (event: any) => {
                handleAdresse({ adresse: event.target.value });
            });
        }
        return () => {
            dispatch(changeCurrentErrorMessage(currentErreurMessage ?? null));
            dispatch(changeIsButtonEnabled(false));
            if (inputElement) {
                inputElement.removeEventListener('input', handleAdresse);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "coordonees-sante": data }));
        isStepDataValid() ? dispatch(changeIsButtonEnabled(true)) : dispatch(changeIsButtonEnabled(false));
    }, [data]);


    const setStepData = (key: string, value: any) => {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const isStepDataValid = () => {
        return (
            data["nom"] !== "" && data["prenom"] !== "" &&
            phoneNumberRegex.test(data["telephone"]) && emailRegEx.test(data["email"]) && data["accept_data"] &&
            data["adresse_actuelle"]["adresse"] !== "" && data["adresse_actuelle"]["adresse"] !== null &&
            data["adresse_actuelle"]["ville"] !== "" && data["adresse_actuelle"]["ville"] !== null &&
            codePostalRegex.test(data["adresse_actuelle"]["code_postal"])
        )
    };

    const removeConjointAndEnfantKey = () => {
        const newData = { ...data };
        delete newData["conjoint"];
        delete newData["enfants"];
        setData(newData);
    };

    const handleAdresse = (addressObject: { [key: string]: any }) => {
        const newData = { ...data };
        const updatedAdresseActuelle = { ...newData.adresse_actuelle };

        for (const key in addressObject) {
            if (addressObject.hasOwnProperty(key)) {
                updatedAdresseActuelle[key] = addressObject[key];
            }
        }
        setStepData("adresse_actuelle", updatedAdresseActuelle);
    };

    const handleSelectAdresse = (suggestion: any) => {
        const adresseObject = {
            adresse: suggestion.description,
            ville: suggestion.terms.length > 1 ? suggestion.terms[suggestion.terms.length - 2].value : suggestion.terms[0].value
        }
        handleAdresse(adresseObject);
    };


    return (
        <Stack width={"100%"} spacing={2}>
            <>
                {currentErreurMessage && (<Error error={currentErreurMessage} />)}
                <h3 className='century-bold m-0'>Vous</h3>
                <Stack direction={'row'} gap={"10px"} pb={5} width={"100%"}>
                    <FormControl fullWidth required>
                        <Label label={"Nom"} />
                        <TextField onChange={(event: any) => setStepData("nom", event.target.value)} variant="outlined" value={data["nom"]} />
                    </FormControl>
                    <FormControl fullWidth required>
                        <Label label={"Prénom"} />
                        <TextField onChange={(event: any) => setStepData("prenom", event.target.value)} variant="outlined" value={data["prenom"]} />
                    </FormControl>
                </Stack>
            </>
            <>
                <h3 className='century-bold m-0'>Vos coordonnées</h3>
                <FormControl fullWidth required>
                    <Label label={"Adresse"} />
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyBA1dplU9rdYSCSnwdrIFg_zuoaqcxW4Rc"
                        placeholder="Indiquez un lieu"
                        loader={<p className='century-bold' style={{ fontSize: 14 }}>Chargement des adresses...</p>}
                        initialValue={data["adresse_actuelle"]["adresse"] ?? ""}
                        onSelect={handleSelectAdresse}
                        renderSuggestions={(_active, suggestions, onSelectSuggestion) => (
                            <div className="suggestions-container">
                                {suggestions.map((suggestion, index) => (
                                    <MenuItem key={index} onClick={(event) => onSelectSuggestion(suggestion, event)}>
                                        {suggestion.description}
                                    </MenuItem>
                                ))}
                            </div>
                        )}
                    />
                </FormControl>
                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                    <FormControl fullWidth required>
                        <Label label={"Code postal"} />
                        <TextField onChange={(event: any) => handleAdresse({ code_postal: event.target.value })} variant="outlined" value={data["adresse_actuelle"]["code_postal"] ?? ""} />
                    </FormControl>
                    <FormControl fullWidth required>
                        <Label label={"Ville"} />
                        <TextField onChange={(event: any) => handleAdresse({ ville: event.target.value })} variant="outlined" value={data["adresse_actuelle"]["ville"] ?? ""} />
                    </FormControl>
                </Stack>
                <Stack direction={'row'} gap={"10px"} pb={5} width={"100%"}>
                    <FormControl fullWidth required>
                        <Label label={"Nº de téléphone"} />
                        <TextField onChange={(event: any) => setStepData("telephone", event.target.value)} variant="outlined" value={data["telephone"]} />
                    </FormControl>
                    <FormControl fullWidth required>
                        <Label label={"Email"} />
                        <TextField onChange={(event: any) => setStepData("email", event.target.value)} variant="outlined" value={data["email"]} />
                    </FormControl>
                </Stack>
            </>
            <FormControlLabel control={<Checkbox value={data["accept_data"]} onChange={() => setStepData("accept_data", !data["accept_data"])} />}
                label={
                    <small className='checkbox-label'>
                        J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis et accepte qu'on me recontacte dans le cadre de cette démarche
                    </small>
                }
            />
        </Stack>
    );
};