/* eslint-disable react-hooks/exhaustive-deps */
import "dayjs/locale/fr";
import { changeCurrentErrorMessage, changeCurrentSubstep, changeInfo, changeInitDataEmprunteur, changeLoading, changeStepEmprunteur, changeStepsDataEmprunteur } from "../../redux/actions";
import { Fragment, ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomContainer } from "../../components/CustomContainer/CustomContainer";
import { Container, LinearProgress, Stack, ThemeProvider, createTheme, linearProgressClasses, styled, useMediaQuery } from "@mui/material";
import Loading from "../../components/Loading/Loading";
import colors from "../../colors/colors";
import { useDispatch, useSelector } from "react-redux";
// import SignatureEmprunteur from "../../components/ParcoursEmprunteur/Signature/Signature";
// import InfosComplementaires from "../../components/ParcoursEmprunteur/InfosComplementaires/InfosComplementaires";
import OffreEmprunteur from "../../components/ParcoursEmprunteur/OffreEmprunteur/OffreEmprunteur";
import InfosPersonnelles from "../../components/ParcoursEmprunteur/InfosPersonnelles/InfosPersonnelles";
import InfosPret from "../../components/ParcoursEmprunteur/InfosPret/InfosPret";
import InfosBien from "../../components/ParcoursEmprunteur/InfosBien/InfosBien";
import { useLocation, useNavigate } from "react-router-dom";
import ParcoursService from "../../services/services";
import { PiArrowLeftThin } from "react-icons/pi";
import { BsFillHouseFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { RiEdit2Fill } from "react-icons/ri";
import { StepIconProps } from '@mui/material/StepIcon';
import './ParcoursEmprunteur.scss';


const theme = createTheme({
    palette: {
        primary: {
            main: colors.blue
        }
    }
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colors.green,
    },
}));


const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: "#eeeeee",
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: colors.green
    }),
    ...(ownerState.completed && {
        backgroundColor: colors.green
    }),
}));


function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <BsFillHouseFill size={20} />,
        2: <IoStatsChart size={20} />,
        3: <FaUserGroup size={20} />,
        4: <RiEdit2Fill size={20} />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

export default function ParcoursEmprunteur() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const stepEmprunteur = useSelector((store: any) => store.stepEmprunteur.stepEmprunteur);
    const currentSubstep = useSelector((store: any) => store.currentSubstep.currentSubstep);
    const stepsDataEmprunteur = useSelector((store: any) => store.stepsDataEmprunteur.stepsDataEmprunteur);
    const loading = useSelector((store: any) => store.loading.loading);
    const isButtonEnabled = useSelector((store: any) => store.isButtonEnabled.isButtonEnabled);

    const [param, setParam] = useState<string | null>(null);
    const [log, setLog] = useState<string>("");
    const [progressValue, setProgressValue] = useState<number>(0);

    const matches = useMediaQuery(theme.breakpoints.down('md'));


    let signature;
    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;
    const product = "DevisEmprunteurClient";


    useEffect(() => {
        if (!localStorage.getItem('url_ad_emprunteur')) {
            localStorage.setItem('url_ad_emprunteur', window.location.href);
        }
        if (queryParams.get('come_back')) {
            setParam("come_back");
        }
        initStep(steps[stepEmprunteur]?.name);
        dispatch(changeCurrentErrorMessage(null));
    }, []);

    useEffect(() => {
        handleProgressValue();
    }, [stepEmprunteur, currentSubstep]);


    const initStep = async (currentStep: any) => {
        window.scroll(0, 0);
        dispatch(changeLoading(true));
        dispatch(changeCurrentErrorMessage(null));
        await ParcoursService.api_init_step(currentStep, product).then(async (data) => {
            if (data['erreur']) {
                dispatch(changeCurrentErrorMessage(data["erreur"]));
                dispatch(changeLoading(false));
            } else if (data['info']) {
                dispatch(changeInfo(true));
                dispatch(changeLoading(false));
            } else {
                setLog(data['log']);
                dispatch(changeStepsDataEmprunteur(setStepsDataEmprunteur(data['response']['form'], currentStep)));
                dispatch(changeInitDataEmprunteur(data['response']['needed']));
                dispatch(changeCurrentSubstep(0));
                if ('url_signature' in data['response']['needed']) {
                    signature = data['response']['needed']['url_signature'];
                    if (data['response']['needed']['is_redirect'] === true) {
                        window.open(signature, '_self');
                    }
                }
            }
            dispatch(changeLoading(false));
        }).catch(() => {
            console.log("__error__init__step__");
            dispatch(changeCurrentErrorMessage(erreur));
            dispatch(changeLoading(false));
        });
    };

    const apiGoToStep = async (index: number) => {
        // First substep of a step excluding the first step
        if (currentSubstep === 0 && stepEmprunteur !== 0) {
            await ParcoursService.api_go_to_step(steps[index as keyof object]?.name, product).then(async (data: any) => {
                await initStep(data["step"]);
                dispatch(changeStepEmprunteur(getStepIndex(data["step"])));
                dispatch(changeCurrentSubstep(steps[getStepIndex(data["step"])].substep - 1));
            }).catch(() => {
                console.log("__error__go_to__step__");
                dispatch(changeCurrentErrorMessage(erreur));
            });
        }
        // Any substep of any step excluding the first one
        if (currentSubstep !== 0 && currentSubstep <= steps[stepEmprunteur]?.substep - 1) {
            dispatch(changeCurrentSubstep(currentSubstep - 1));
        }
        window.scroll(0, 0);
    };

    const getNextStep = async () => {
        dispatch(changeLoading(true));
        // Navigation between steps
        if ((currentSubstep === steps[stepEmprunteur]?.substep - 1)
        ) {
            window.scroll(0, 0);
            await ParcoursService.api_get_next_step(
                steps[stepEmprunteur].name, product, stepsDataEmprunteur[steps[stepEmprunteur].name], log
            ).then(async (data: any) => {
                if (data['erreur']) {
                    window.scroll(0, 0);
                    dispatch(changeCurrentErrorMessage(data["erreur"]));
                    dispatch(changeLoading(false));
                } else {
                    if (stepEmprunteur === 3) {
                        navigate("/");
                    } else {
                        if ('next_step' in data) {
                            if (data['next_step'] !== '') {
                                await initStep(data["next_step"]);
                                dispatch(changeStepEmprunteur(getStepIndex(data["next_step"])));
                                dispatch(changeCurrentSubstep(0));
                                dispatch(changeLoading(false));
                            }
                        }
                    }
                }
            }).catch(() => {
                console.log("__error__get__next__step__");
                dispatch(changeCurrentErrorMessage(erreur));
                dispatch(changeLoading(false));
            });
        }
        // Navigation between substeps of a step
        else {
            dispatch(changeCurrentSubstep(currentSubstep + 1));
            dispatch(changeLoading(false));
        }
        window.scroll(0, 0);
    };

    const goToLastStep = async () => {
        await ParcoursService.api_go_to_last_step(product).then(async (data: any) => {
            setParam("");
            window.history.replaceState(null, '', window.location.pathname);
            await initStep(data["step"]);
            dispatch(changeStepEmprunteur(getStepIndex(data["step"])));
            dispatch(changeCurrentSubstep(0));
        }).catch(() => {
            console.log("__error__last__step__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const reset = async () => {
        await ParcoursService.api_reset(product).then(async (data: any) => {
            setParam("");
            window.history.replaceState(null, '', window.location.pathname);
            await initStep(data["step"]);
            dispatch(changeStepEmprunteur(getStepIndex(data["step"])));
        }).catch(() => {
            console.log("__error__reset__");
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const setStepsDataEmprunteur = (dataResponseForm: any, currentStep: string) => {
        // Set other keys to empty objects to avoid : 
        // Error storing data DOMException: The quota has been exceeded.
        const updatedState = {
            ...stepsDataEmprunteur,
            [currentStep]: dataResponseForm,
            ...Object.keys(stepsDataEmprunteur)
                .filter((key: string) => key !== currentStep)
                .reduce((acc: any, key: string) => {
                    acc[key] = {};
                    return acc;
                }, {})
        };
        return updatedState;
    };

    const hasPrecedent = () => {
        return (currentSubstep === 0 && stepEmprunteur !== 0) || (currentSubstep !== 0 && currentSubstep <= steps[stepEmprunteur]?.substep - 1);
    };

    const getStepIndex = (stepName: string) => steps.findIndex(step => step.name === stepName);

    const isLoading = () => {
        return (loading && stepEmprunteur !== 3) || (stepEmprunteur !== 5 && Object.keys(stepsDataEmprunteur[steps[stepEmprunteur].name]).length === 0);
    };

    const handleProgressValue = () => {
        if (stepEmprunteur === 0) {
            if (currentSubstep === 0) {
                setProgressValue(0);
            }
            if (currentSubstep === 1) {
                setProgressValue(10);
            }
            if (currentSubstep === 2) {
                setProgressValue(20);
            }
            if (currentSubstep === 3) {
                setProgressValue(25);
            }
        }
        if (stepEmprunteur === 1) {
            if (currentSubstep === 0) {
                setProgressValue(38);
            }
        }
        if (stepEmprunteur === 2) {
            if (currentSubstep === 0) {
                setProgressValue(64);
            }
        }
        if (stepEmprunteur === 3) {
            if (currentSubstep === 0) {
                setProgressValue(100);
            }
        }
    };

    const steps = [
        { index: 0, name: "informations-bien", title: "Projet", substep: 4, component: <InfosBien goNext={getNextStep} /> },
        { index: 1, name: "informations-pret", title: "Situation", substep: 1, component: <InfosPret /> },
        { index: 2, name: "informations-personnelles", title: "Coordonnées", substep: 1, component: <InfosPersonnelles /> },
        { index: 3, name: "offre-emprunteur", title: "Souscription", substep: 1, component: <OffreEmprunteur goNext={getNextStep} /> },
        // { index: 4, name: "informations-complementaires", title: "Informations complémentaires", substep: 2, component: <InfosComplementaires /> },
        // { index: 5, name: "signature", title: "Signature", substep: 1, component: <SignatureEmprunteur /> }
    ];

    console.log("step:", stepEmprunteur, "sub:", currentSubstep, progressValue);

    return (
        <div style={{ overflowX: "hidden", backgroundColor: "#fafafa" }}>
            <CustomContainer>
                <Stack pt={18} pb={10}>
                    <Box sx={{ width: '100%' }}>
                        <Stack className='substep-section' width={"100%"}>
                            <BorderLinearProgress variant="determinate" value={progressValue} />
                            <Stepper className="stepper-emp" alternativeLabel
                                activeStep={stepEmprunteur}>
                                {steps.map((step, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: { optional?: ReactNode } = {};
                                    return (
                                        <Step key={index} {...stepProps}>
                                            <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                                                {matches ? (<small>{step.title}</small>) : step.title}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <Fragment>
                                {
                                    (isLoading()) ? (
                                        <Loading />
                                    ) : (
                                        <ThemeProvider theme={theme}>
                                            <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
                                                {steps[stepEmprunteur]?.component}
                                                {
                                                    (stepEmprunteur === 3) && (
                                                        <small className='century' style={{ textAlign: "right", marginTop: 20 }}>
                                                            Cliquer sur 'Souscrire' finalisera votre souscription et vous redirigera vers la page d'accueil
                                                        </small>
                                                    )
                                                }
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: (stepEmprunteur === 3) ? 2 : 6 }}>
                                                    {
                                                        hasPrecedent() && (
                                                            <Button className="btn-precedent-emp" startIcon={<PiArrowLeftThin color={colors.pink} />} onClick={() => { apiGoToStep(stepEmprunteur - 1) }} variant="text">
                                                                PRECEDENT
                                                            </Button>
                                                        )
                                                    }
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Button className={(isButtonEnabled && !loading) ? "btn-suivant-emp" : "disabled-btn-emp"} disabled={!(isButtonEnabled && !loading)}
                                                        variant="contained"
                                                        onClick={() => getNextStep()}>
                                                        {(stepEmprunteur === 3) ? 'SOUSCRIRE' : 'SUIVANT'}
                                                    </Button>
                                                </Box>
                                            </LocalizationProvider>
                                        </ThemeProvider>
                                    )
                                }
                            </Fragment>
                        </Stack>
                    </Box>
                </Stack>
            </CustomContainer>
            {
                param === "come_back" && (
                    <Container maxWidth={false} className="bandeau" sx={{ py: 7 }}>
                        <Stack textAlign={"center"} alignItems={"center"} spacing={5}>
                            <p className="m-0">Hello 👋 <br />
                                Vous avez déjà effectué un devis sur notre site il y a quelques temps !
                            </p>
                            <Stack direction={{ md: "row", xs: "column" }} flexWrap={"wrap"} gap={{ md: 5, xs: 2 }} alignItems={"center"} mt={2}>
                                <Button className="btn-suivant" onClick={() => { goToLastStep() }} variant="contained">
                                    Reprendre mon devis
                                </Button>
                                <small style={{ color: "#777" }}>ou</small>
                                <Button className="btn-suivant" onClick={() => { reset() }} variant="contained">
                                    Recommencer un devis
                                </Button>
                            </Stack>
                        </Stack>
                    </Container>
                )
            }
        </div>
    );
};