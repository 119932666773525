/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentErrorMessage, changeInitData, changeIsButtonEnabled, changeLoading, changeStepsData } from '../../../redux/actions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import hospitalisationIcon from "../../../assets/icons/hospitalisation.svg";
import optiqueIcon from "../../../assets/icons/optique.svg";
import dentaireIcon from "../../../assets/icons/dentaire.svg";
import specialisteIcon from "../../../assets/icons/spécialistes.svg";
import praticienIcon from "../../../assets/icons/medecine douce.svg";
import Slider from 'rc-slider';
import ParcoursService from '../../../services/services';
import '../ParcoursSante.scss';


const besoins = [
    { title: "Hospitalisation", icon: hospitalisationIcon, name: "hospi" },
    { title: "Dentaire", icon: dentaireIcon, name: "dentaire" },
    { title: "Médecine douce", icon: praticienIcon, name: "medecine_douce" },
    { title: "Optique", icon: optiqueIcon, name: "optique" },
    { title: "Soins courants", icon: specialisteIcon, name: "soins_courant" },
];

const markNoLabels = { 1: "", 2: "", 3: "", 4: "" };

const options: any = {
    "Option 1": [160, 180, 52, 25],
    "Option 2": [210, 250, 230, 30],
    "Option 3": [310, 300, 305, 40],
    "Option 4": [340, 385, 380, 45],
};


export default function FormuleSante() {
    const dispatch = useDispatch();
    const initData = useSelector((store: any) => store.initData.initData);
    const stepsData = useSelector((store: any) => store.stepsData.stepsData);
    const [data, setData] = useState<object>(stepsData["formule-sante"]);
    const product = "DevisSanteClient";


    useEffect(() => {
        window.scroll(0, 0);
        dispatch(changeIsButtonEnabled(true));
        return () => {
        }
    }, []);

    useEffect(() => {
        dispatch(changeStepsData({ ...stepsData, "formule-sante": data }));
    }, [data]);


    const setCritereValue = (critere: string, value: number) => {
        setData((prevState: any) => ({
            ...prevState,
            [critere]: value,
        }));
    };

    const getTarif = async () => {
        dispatch(changeLoading(true));
        await ParcoursService.api_get_tarif("formule-sante", product, stepsData["formule-sante"]).then((data: any) => {
            if (data['erreur']) {
                dispatch(changeLoading(false));
                dispatch(changeCurrentErrorMessage(data["erreur"]));
            } else {
                dispatch(changeLoading(false));
                dispatch(changeStepsData({ ...stepsData, "formule-sante": data['form'] }));
                dispatch(changeInitData(data['needed']));
            }
        }).catch(() => {
            dispatch(changeLoading(false));
            console.log("__error__get__tarif__");
        });
    };

    return (
        <section>
            <h3 className='century-bold m-0 align-center'>La formule recommandée </h3>
            <Grid container spacing={4} alignItems={"start"} mt={3}>
                <Grid container item md={5} xs={12} >
                    <Stack width={"100%"} bgcolor={"#f3f3f3"} justifyContent={"center"} py={3} px={1}>
                        <p className='m-0 align-center'> Vos besoins de santé</p>
                        <TableContainer component={"div"} sx={{ my: 2 }}>
                            <Table >
                                <TableBody>
                                    {
                                        besoins.map((bes, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" >
                                                    <Stack direction={"row"} alignItems={"center"} ml={2}>
                                                        <Box component="img" src={bes.icon} mr={1} width={30} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center" colSpan={4} width={"100%"}>
                                                    <Stack px={1.5}>
                                                        <Slider
                                                            dots min={1} max={4} step={1} marks={markNoLabels}
                                                            onChange={(value: any) => setCritereValue(bes.name, value)}
                                                            defaultValue={stepsData["formule-sante"][bes.name]}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <p onClick={() => getTarif()} className='m-0 align-center btn-modifier'> MODIFIER</p>
                    </Stack>
                </Grid>
                <Grid container item md={7} xs={12} >
                    <Stack width={"100%"} >
                        <Stack bgcolor={"#eceeff"} py={3} px={1.5} textAlign={"center"}>
                            <p className='m-0'>
                                Formule <span className='text-bold'>{initData['nom_produit']} {initData['nom_formule']}</span>
                            </p>
                            <p className='asar' style={{ marginTop: 20, marginBottom: 0, fontSize: 35 }}>
                                {initData['prime_mensuel']}€/mois
                            </p>
                        </Stack>
                    </Stack>
                    {
                        initData['provenance'] === 'ugip' && (
                            <Stack className="plus-formule">
                                <ul>
                                    <li>Consultations et honoraires chirurgicaux jusqu'à
                                        <span className="text-bold"> {options[initData['nom_formule']][0]}%</span>
                                    </li>
                                    <li>Prothèse dentaire jusqu'à
                                        <span className="text-bold"> {options[initData['nom_formule']][1]}%</span>
                                    </li>
                                    <li>Monture et verres jusqu'à
                                        <span className="text-bold" > {options[initData['nom_formule']][2]}€</span>
                                    </li>
                                    <li>Consultations chez Ostéopathe, Chiropraticien, Diététicien, Nutritionniste, Psychologue, Sophrologue, Pédicurie jusqu'à 8 séances par an et
                                        <span className="text-bold" > {options[initData['nom_formule']][3]}€</span> par consultation
                                    </li>
                                </ul>
                            </Stack>
                        )
                    }
                    {
                        initData['provenance'] === 'neoliane' && (
                            <Stack className="plus-formule">
                                <ul>
                                    <li>Prothèses dentaires remboursées jusqu’à 300% de la base de remboursement
                                    </li>
                                    <li>Monture et verres remboursés jusqu’à 400€ par équipement
                                    </li>
                                    <li>Consultations et honoraires chirurgicaux remboursés jusqu’à 300% de la base de remboursement la première année et jusqu’a 350% à partir de la 3e année
                                    </li>
                                </ul>
                            </Stack>
                        )
                    }
                    {
                        initData['provenance'] === 'ugip' && (
                            <Stack className="modify" >
                                <a href="https://garanties-sante.s3.eu-west-3.amazonaws.com/ugip/Garanties_equilibre_plus.pdf" target="_blank" rel='noreferrer'>
                                    Voir le tableaux de garanties
                                </a>
                            </Stack>
                        )
                    }
                    {
                        initData['provenance'] === 'neoliane' && (
                            <Stack className="modify" >
                                <a href="https://garanties-sante.s3.eu-west-3.amazonaws.com/neoliane/Garanties_initial_plus.pdf" target="_blank" rel='noreferrer'>
                                    Voir le tableaux de garanties
                                </a>
                            </Stack>
                        )
                    }
                    <hr style={{ width: "100%", color: "#e5e5e5", marginTop: 20 }} />
                    {
                        (initData['provenance'] === 'ugip' || initData['provenance'] === 'julia') && (
                            <Stack className="point-fort" >
                                <h2 className='asar m-0'>Les Points Forts</h2>
                                <ul>
                                    <li>
                                        Garanties <span>100% Santé</span> (Reste à charge 0%)
                                    </li>
                                    <li>
                                        <span>Bonus Fidélité</span> sur les postes : Soins courants, Dentaire,Hospitalisation
                                    </li>
                                    <li>
                                        <span>Médecine douce</span> prise en charge (Psychologue, Ostéopathe, Pédicure, nutritionniste )
                                    </li>
                                    <li>
                                        <span>Télétransmission de vos frais</span> de santé avec votre caisse de régime obligatoire
                                    </li>
                                </ul>
                            </Stack>
                        )
                    }
                    {
                        initData['provenance'] === 'neoliane' && (
                            <Stack className="point-fort" >
                                <h2 className='asar m-0'>Les Points Forts</h2>
                                <ul>
                                    <li>
                                        Garanties <span>100% Santé</span> (Reste à charge 0%)
                                    </li>
                                    <li>
                                        <span>Bonus fidélité</span> sur les postes : Médecines douces, Soins courants, Dentaire, Hospitalisation
                                    </li>
                                    <li>
                                        Consultations chez un Ostéopathie, Chiropractie, Acuponcture, Etiopathie, Pédicure, Homéopathie remboursées jusqu’à 110€ par an et 130€ la 3e année
                                    </li>
                                    <li>
                                        <span>Télétransmission de vos frais</span> de santé avec votre caisse de régime obligatoire
                                    </li>
                                </ul>
                            </Stack>
                        )
                    }
                </Grid>
            </Grid >
        </section >
    )
};